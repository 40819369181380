<template>


<div>

<CreateMitglieder :title='message' :speichern="speichern" :abbrechen="abbrechen" :bearbeiten="bearbeiten"/>

</div>
    

</template>


<script>
import CreateMitglieder from '../components/CreateMitglieder'


export default {
 name: 'MitgliederCreateMitglied',
  data(){
         return {
             message:"Neues Mitglied erstellen",
             speichern:"Neues Mitglied abspeichern",
             abbrechen:"Mitglied erstellung abbrechen",
             bearbeiten:false
         }
     },
     components: {
         CreateMitglieder
     }
}
     
</script>



<style scoped>

</style>
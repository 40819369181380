<template>

    <div class="relative h-screen xl:flex">
    
        <Sidebar />
    
        <!-- content Sidebar -->
        <div class="flex-1 pr-2 pl-2 md:pr-4 md:pl-4 lg:pr-8 lg:pl-8 pb-4 h-screen md:overflow-auto">
            <div class="mb-4">
                <div>
                 <PerformanceComponent/>
                </div>
            </div>
        </div>
    </div>
    
    
    
    </template>
    
    
    <script>
    import Sidebar from '../components/Sidebar' 
    import PerformanceComponent from '../components/PerformanceComponent.vue'
    
    
    export default {
     name: 'Hilfsprojekte',
         components: {
             Sidebar,
             PerformanceComponent
         }
    }
         
    </script>
    
    
    
    <style scoped>
    
    </style>
<script>
import dataService from "../services/dataService";
import jsonLaenderListe from "../assets/countries.json";
export default {
  data() {
    return {
        projekteLaden: "",
        projekteVerarbeiten: "",
        laenderLaden: "",
        laenderListe: [],
        startTime: 0,
        endTime: 0,
        timeDiff: 0,
        internetSpeedData: 0
    }
  },
  methods: {
    startTimer: function() {
        this.startTime = new Date();
    },
    endTimer: function() {
        this.endTime = new Date();
        this.timeDiff = this.endTime - this.startTime; //in ms
        return Math.round(this.timeDiff);
    },
    runTest: async function() {
        document.getElementById("startButton").style.display = "none";
        document.getElementById("loadingButton").style.display = "block";
        document.getElementById("testContainer").style.display = "block";
        
        //Testing API
        document.getElementById("projekteApi").style.display = "block";
        document.getElementById("projekteApi").style.backgroundColor = "#bbdefb";
        this.startTimer();
        const response = await dataService.getAlleProjekte();
        this.projekteLaden = this.endTimer() + " ms";
        document.getElementById("projekteApi").style.backgroundColor = "#c8e6c9";

        //Testing Client Projekte
        document.getElementById("projekteClient").style.display = "block";
        document.getElementById("projekteClient").style.backgroundColor = "#bbdefb";
        this.startTimer();
        response.data.projekte.forEach((gefundenesProjekt) => {
        if (gefundenesProjekt.zahlungen.length > 0) {
          gefundenesProjekt.Status = this.getStatus(
            gefundenesProjekt.zahlungen
          );
          gefundenesProjekt.Waehrung = gefundenesProjekt.zahlungen[0].Waehrung;
          gefundenesProjekt.Betrag = this.getBetrag(
            gefundenesProjekt.zahlungen
          );
        } else {
          gefundenesProjekt.Status = "Erfasst";
          gefundenesProjekt.Betrag = "";
        }
        if (
          gefundenesProjekt.kontaktperson == null ||
          gefundenesProjekt.kontaktperson.Organisation == null
        ) {
          gefundenesProjekt.kontaktperson = {
            Organisation: "",
          };
        }
        if (
          gefundenesProjekt.land == null ||
          gefundenesProjekt.land.Name == null
        ) {
          gefundenesProjekt.land = {
            Name: "",
          };
        }
      });
      this.projekteVerarbeiten = this.endTimer() + " ms";
      document.getElementById("projekteClient").style.backgroundColor = "#c8e6c9";

        //Testing Laender
        document.getElementById("laender").style.display = "block";
        document.getElementById("laender").style.backgroundColor = "#bbdefb";
        this.startTimer();
        for (let index = 0; index < jsonLaenderListe.length; index++) {
            this.laenderListe.push(jsonLaenderListe[index]);
        }
        this.laenderLaden = this.endTimer() + " ms";
        document.getElementById("laender").style.backgroundColor = "#c8e6c9";

        //EndTesting
        document.getElementById("internetverbindung").style.display = "block";
        document.getElementById("sendResults").style.display = "block";

        document.getElementById("startButton").style.display = "block";
        document.getElementById("loadingButton").style.display = "none";
    },
    getStatus(zahlungen) {
      for (let index = 0; index < zahlungen.length; index++) {
        const element = zahlungen[index];
        if (element.Status != "Bezahlt") {
          return element.Status;
        }
      }
      return zahlungen[zahlungen.length - 1].Status;
    },

    getBetrag(zahlungen) {
      var betrag = 0;
      for (let index = 0; index < zahlungen.length; index++) {
        betrag += zahlungen[index].Betrag;
      }
      //Betrag mit hochkomma darstellen
      betrag = betrag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
      if (
        betrag.toString().split(".")[1] != undefined &&
        betrag.toString().split(".")[1].length > 0 &&
        betrag.toString().split(".")[1].length < 2
      ) {
        betrag += "0";
      }
      return betrag;
    }
  }
}
</script>

<template>
  <h1 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-6">Performance Analytics</h1>
  <p>Dieser Test misst die Performance der Hilfsprojektverwaltung auf deinem Endgerät. Starte den Test durch einen Klick auf den folgenden Button:</p>

  <button id="startButton" @click="runTest" type="button" class="mt-5 text-white text-base font-bold bg-green-600 hover:bg-green-500 font-medium rounded-lg px-5 py-2.5 text-center mr-2 inline-flex items-center">
    Test starten
 </button>

  <button id="loadingButton" disabled type="button" class="mt-5 hidden cursor-wait text-white text-base font-bold bg-gray-400 font-medium rounded-lg px-5 py-2.5 text-center mr-2 inline-flex items-center">
    <svg aria-hidden="true" role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
    </svg>
    Test läuft
 </button>
 <div id="testContainer" class="hidden">
    <h1 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-6">Tests</h1>
    <a id="projekteApi" ref="#" class=" hidden mt-5 block p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 class="mb-1 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Projekte laden (API)</h5>
        <p class="font-normal text-gray-700 dark:text-gray-400">Zeit um die Projekte aus der Datenbank zu laden: <span class="font-bold">{{projekteLaden}}</span></p>
    </a>
    <a id="projekteClient" class=" hidden mt-5 block p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Projekte laden (Client)</h5>
        <p class="font-normal text-gray-700 dark:text-gray-400">Zeit um die Projekte zu verarbeiten: <span class="font-bold">{{projekteVerarbeiten}}</span></p>
    </a>
    <a id="laender" class=" hidden mt-5 block p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Länder laden</h5>
        <p class="font-normal text-gray-700 dark:text-gray-400">Zeit um verfügbare Länder zu laden: <span class="font-bold">{{laenderLaden}}</span></p>
    </a>
    <div id="internetverbindung" class=" hidden mt-5 block p-6 bg-gray-200 border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">Optional: Internetverbindung testen</h5>
        <p class="font-normal text-gray-700 dark:text-gray-400">Internetverbindung testen: <a target="_blank" class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.speedtest.net/">Hier testest du deine Internet-Geschwindigkeit</a></p>
        <label class="mt-3 block text-gray-700 text-sm font-bold mb-2" for="internetSpeed">
        Internet-Geschwindigkeit in Mbps
      </label>
      <input v-model="internetSpeedData" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="internetSpeed" type="number" placeholder="100">
    </div>
    <div id="sendResults" class="hidden">
        <p class="font-bold mt-10 mb-0">Die Resultate des Tests kannst du mit dem folgenden Button direkt an die Entwickler senden.<br>(Es öffnet sich dein Mail-Programm, in welchem du die Nachricht absenden musst.)</p>
        <a :href="`mailto:lars.vognstrup@bluewin.ch,michi.pe@hotmail.com?subject=[Hilfsprojekt-Verwaltung] Performance Analytics&body=Neues Performance Resultat%0D%0AProjekte laden: ${projekteLaden}%0D%0AProjekte verarbeiten: ${projekteVerarbeiten}%0D%0ALänder verarbeiten: ${laenderLaden}%0D%0AInternet-Speed: ${internetSpeedData}`"
        id="sendResults" @click="sendResults" type="button" class="cursor-pointer mt-1 text-white text-base font-bold bg-green-600 hover:bg-green-500 font-medium rounded-lg px-5 py-2.5 text-center mr-2 inline-flex items-center">
        Resultate an Entwickler senden
        </a>
    </div>
</div>
</template>
import axios from 'axios';
import authHeader from './auth-header';

class AuthService {
  login(user) {
    return axios
      .post('http://185.142.212.118:8080/api/auth/login', {
        passwort: user.passwort
      })
      .then(response => {
        if (response.data.accessToken != null) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  checkToken() {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'get',
          url: 'http://185.142.212.118:8080/api/auth/verifytoken',
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();
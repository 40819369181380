<template>
  <div class="sticky top-0">
    <!-- mobile menu bar -->
    <div
      class="bg-green-900 flex justify-between xl:hidden text-center text-white"
    >
      <!-- mobile menu button -->
      <button
        v-on:click="mobileButton"
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
      >
        <!-- Icon Hamburger-Button -->
        <svg
          class="w-6 h-6 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"
          />
        </svg>
      </button>

      <!-- text -->
      <a href="#" class="block p-4 mr-12 font-bold">Hilfsprojekte Verwaltung</a>
    </div>

    <!-- sidebar -->
    <div
      class="
        text-white
        sidebar
        bg-green-800
        h-screen
        xl:w-48
        w-screen
        absolute
        inset-y-0
        left-0
        transform
        -translate-x-full
        xl:relative xl:translate-x-0
        transition
        duration-200
        ease-in-out
        border-r-2
        z-100
      "
    >
      <!-- text -->
      <div class="h-1/6 bg-green-900 p-7 text-center border-b-2">
        <a href="#" class="">
          <div class="text-2xl font-semibold">Hilfsprojekte</div>
          <div class="text-2xl font-semibold">Verwaltung</div>
        </a>
      </div>

      <!-- nav px= leftright py= top bottom-->
      <div v-on:click="hideSidebar" class="h-5/6 text-lg">
        <nav class="h-5/6 pt-2">
          <div
            @click="onHilfsprojekte()"
            id="hilfsprojekteSidebar"
            class="
              cursor-pointer
              py-3
              px-4
              rounded
              transition
              duration-200
              hover:bg-green-700
            "
          >
            <a class="inline-flex items-center">
              <!-- Icon -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 10h16M4 14h16M4 18h16"
                />
              </svg>
              Hilfsprojekte
            </a>
          </div> 
         <!--
          <div @click="onMitglieder()"
          id="mitgliederSidebar"
            class="cursor-pointer py-3 px-4 rounded transition duration-200 hover:bg-green-700"
          >
            <a class="inline-flex items-center">
         
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              Mitglieder
            </a>
          </div>
          <div
            @click="onGVEinladungen()" class="py-3 px-4 rounded transition duration-200 hover:bg-green-700 cursor-pointer"
          >
            <a class="inline-flex items-center">
           
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              GV-Einladung
            </a>
          </div>
          -->
          <div>
            <div
              @click="onLogout()"
              class="
                cursor-pointer
                py-3
                px-4
                rounded
                transition
                duration-200
                hover:bg-green-700
              "
            >
              <a class="inline-flex items-center" @click.prevent="logOut">
                <!-- Icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                Abmelden
              </a>
            </div>
          </div>
        </nav>
        <div class="h-1/6 bg-white">
          <img class="object-contain h-full w-full" src=@/assets/logo.png />
        </div>
      </div>
      <!-- logo -->
    </div>
  </div>
</template>


<script>
export default {
  name: "Sidebar",

  props: ["title"],

  methods: {
    //Hamburger Button aktivieren der Sidebar
    mobileButton: function () {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");

      //Den Header nicht mehr Sticky machen, damit er nicht die Mobile Sidebar überlappt
      const stickyHeader = document.getElementById('stickyHeaderButtons');
      stickyHeader.classList.add('hidden');

      //Tabelle ausblenden, damit Navi nicht gescrollt werden kann
      
      const tableBody = document.getElementById('projectTableBody');
      tableBody.classList.add('hidden');
    },
    //Deaktivieren der Sidebar
    hideSidebar: function () {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    },
    onLogout() {
      this.$router.push("/");
    },

    onHilfsprojekte() {
      //Die Buttons wieder Sticky machen, weil Mobile Navi weggeht.
      var element = document.getElementById('stickyHeaderButtons');
      if(element != undefined && element != null){
      console.log(element)
      element.classList.remove('hidden');

      const tableBody = document.getElementById('projectTableBody');
      if(tableBody != undefined && tableBody != null) {
tableBody.classList.remove('hidden');
      }
      
      }

      this.$router.push("/hilfsprojekte");
    },

    
   // onMitglieder() {

   //   this.$router.push("/mitglieder")

   // },
   // onGVEinladungen() {

   //   this.$router.push("/gv-einladungen")

  //  },

  
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    }
  },

  components: {},
};
</script>





<style scoped>
</style>
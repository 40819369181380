<template>
  <div class="py-10">
    <h1 class="text-2xl font-bold">GV Einladungen (experimentell)</h1>
    <p class="text-red-500 font-semibold">Diese Seite ist noch im Aufbau.</p>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-5">
      <div>
        <div class="shadow p-8">
          <h2 class="text-2xl font-bold">Anleitung</h2>
          <h4 class="text-lg font-semibold">Benutzen des Brief-Generators</h4>
          <p>
            Um die GV-Einladungen zu erstellen kann wie folgt vorgegangen
            werden:
          </p>
          <br />
          <ul class="list-decimal pl-5">
            <li class="py-1">
              <p>
                Mit dem Button "Datei auswählen" kann die Word-Vorlage
                ausgewählt werden, welche bereits zum befüllen vorbereitet
                wurde.
              </p>
            </li>
            <li class="py-1">
              <p>
                Sobald die Word-Vorlage ausgewählt wurde, kann mit dem Button
                "Starten" die Generierung gestartet werden
              </p>
            </li>
            <li class="py-1">
              <p>
                Achtung: Während der Erstellung kann es vorkommen, dass die
                Website kurzzeitig "hängt". Dies ist normal, sollte aber nicht
                länger als 1-2min andauern.
              </p>
            </li>
            <li class="py-1">
              <p>
                Sobald die Generierung abgeschlossen ist, wird ein .zip Ordner
                mit allen GV-Einladungen heruntergeladen
              </p>
            </li>
            <li class="py-1">
              <p>
                .zip Ordner extrahieren und ggf. noch manuelle Anpassungen
                vornehmen.
              </p>
            </li>
          </ul>
          <br />
          <h4 class="text-lg font-semibold">Word Template erstellen</h4>
          <p>Das Word Template ist eine normale Word Datei (.docx), welche mit Platzhaltern ergänzt wird.</p>
          <p>Die Platzhalter werden mit den Daten der jeweiligen Mitgliedern ersetzt. Eine Liste der Platzhalter ist in der Tabelle einsehbar.</p>
          <p>Die Platzhalter können direkt aus der Tabelle, in die Word-Vorlage kopiert werden.</p>
          <div class="align-middle inline-block min-w-full my-2">
            <div class="shadow overflow-auto border-gray-500 rounded-t-lg">
              <table
                class="
                  max-w-full
                  divide-y divide-gray-200
                  table-fixed
                  w-full
                  sm:overflow-x-scroll
                "
              >
                <thead class="bg-gray-200 max-h-10">
                  <tr>
                    <th
                      class="
                        px-1
                        py-2
                        md:px-6 md:py-3 md:w-1/12
                        w-3/12
                        md:w-1/12
                        text-left text-xs
                        font-medium
                        text-gray-800
                        uppercase
                        tracking-wider
                      "
                    >
                      Platzhalter
                    </th>
                    <th
                      class="
                        px-1
                        py-2
                        md:px-6 md:py-3 md:w-1/12
                        w-3/12
                        md:w-1/12
                        text-left text-xs
                        font-medium
                        text-gray-800
                        uppercase
                        tracking-wider
                      "
                    >
                      Beschreibung
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="
                    bg-white
                    divide-y divide-gray-200
                    text-gray-800 text-sm
                    break-words
                  "
                >
                  <tr>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p><strong>{Organisation}</strong></p>
                    </td>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p>Name der erfassten Organisation des Mitglieds.</p>
                      <p>Falls keine Organisation beim Mitglied erfasst ist, wird der Platzhalter gelöscht.</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p><strong>{Anrede}</strong></p>
                    </td>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p>Erfasste Anrede für die Adresse des Mitglieds</p>
                      <p>Beispiele: "Herr", "Frau", "Herr + Frau"</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p><strong>{Adresse}</strong></p>
                    </td>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p>Erfasste Adresse des Mitglieds</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p><strong>{PLZ}</strong></p>
                    </td>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p>Erfasste Postleitzahl des Mitglieds</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p><strong>{Ort}</strong></p>
                    </td>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p>Erfasster Ort des Mitglieds</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p><strong>{Briefanrede}</strong></p>
                    </td>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p>Erfasste Briefanrede des Mitglieds</p>
                      <p>Beispiele: "Sehr geehrter Herr", "Sehr geehrte Frau"</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p><strong>{BriefanredeNachname}</strong></p>
                    </td>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p>Der Nachname der ersten (oder einzigen) erfassten Person</p>
                      <p>Kann z.B. nach der Briefanrede verwendet werden.</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p><strong>{#Personen}</strong></p>
                      <p><strong>{Vorname} {Name}</strong></p>
                      <p><strong>{/Personen}</strong></p>
                    </td>
                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <p>Dies ist eine Schleife über alle erfassten Personen</p>
                      <p>Es werden jeweils der Vorname und der Nachname eingesetzt.</p>
                      <p>(Durch entfernen von {Vorname} bzw. {Nachname} kann auch nur der andere Wert pro Person eingesetzt werden.)</p>
                      <p>Beispiel der Schleife: Wenn 2 Personen erfasst sind, wird auf die erste Zeile der Vorname und Nachname der 1. Person und auf die 2. Zeile der Vorname und Nachname der 2. Person eingesetzt.</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="shadow p-8">
          <h2 class="text-2xl font-bold">Brief-Generator</h2>
          <p class="mb-3">Die generierten GV-Einladungen werden nach Beendigung der Generierung direkt heruntergeladen.</p>
          <input type="file" id="doc" />
          <button class="bg-green-500
              hover:bg-green-600
              text-white
              font-bold
              py-2
              px-6
              rounded
              inline-flex
              items-center" @click="getDocuments">Starten</button>
              <p class="text-gray-700" id="generierung"></p>
        </div>
        
      </div>
    </div>
  </div>
</template>





<script>
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { saveAs } from "file-saver";
import dataService from "../services/dataService";

export default {
  name: "GVEinladungenForm",

  data() {
    return {};
  },
  async mounted() {},
  watch: {},

  methods: {
    async getDocuments() {
      document.getElementById("generierung").innerText = "Die generierung der GV-Einladung ist in Arbeit. Dies kann einen Moment dauern. Bitte schliessen Sie die Website nicht."
      await this.generate();

    },
    async generate() {
      //Daten holen
      var zipDocs = new PizZip();
      var resonse = await dataService.getAlleMitglieder();
      var mitglieder = resonse.data;

      var docs = document.getElementById("doc");
      var reader = new FileReader();
      if (docs.files.length === 0) {
        alert("Es wurde keine Datei eingelesen");
      }
      reader.readAsBinaryString(docs.files.item(0));

      reader.onerror = function (evt) {
        console.log("error reading file", evt);
        alert("error reading file" + evt);
      };
      reader.onload = function (evt) {
        const content = evt.target.result;
        for (let i = 0; i < mitglieder.length; i++) {
          const mitglied = mitglieder[i];
          var zip = new PizZip(content);

          var BriefanredeNachnameFill = "";
          if (mitglied.Personen.length > 0) {
            BriefanredeNachnameFill = mitglied.Personen[0].Name;
          }

          //Dokument wurde geladen
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });
          doc.setData({
            Anrede: mitglied.Anrede != undefined ? mitglied.Anrede : null,
            Personen: mitglied.Personen,
            Adresse: mitglied.Adresse != undefined ? mitglied.Adresse : null,
            PLZ: mitglied.PLZ != undefined ? mitglied.PLZ : null,
            Ort: mitglied.Ort != undefined ? mitglied.Ort : null,
            Briefanrede:
              mitglied.Briefanrede != undefined ? mitglied.Briefanrede : null,
            BriefanredeNachname: BriefanredeNachnameFill,
            Organisation:
              mitglied.Organisation != null ? mitglied.Organisation.Name : "",
          });

          try {
            doc.render();
          } catch (error) {
            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join("\n");
              console.log("errorMessages", errorMessages);
            }
            throw error;
          }
          //Dateinamen generieren
          var PersonenNamen = "";
          if (mitglied.Organisation != null) {
            // "/" Zeichen aus Ort nehmen, da sonst File mit Ordner erstellt wird.
            var organisationsnameOhneSlash = mitglied.Organisation.Name.replace(
              /\//g,
              " "
            );
            PersonenNamen += organisationsnameOhneSlash + "-";
          }
          for (let index = 0; index < mitglied.Personen.length; index++) {
            const element = mitglied.Personen[index];
            PersonenNamen += element.Name + "_" + element.Vorname + "-";
          }

          // "/" Zeichen aus Ort nehmen, da sonst File mit Ordner erstellt wird.
          var ortname = mitglied.Ort.replace(/\//g, " ");

          var name = PersonenNamen + mitglied.PLZ + "-" + ortname + "-" + i;

          var out = doc.getZip().generate();
          zipDocs.file(name + ".docx", out, { base64: true });
        }
        var datei = zipDocs.generate({ type: "blob" });

        saveAs(datei, "gv-einladungen.zip");
      };
    },
  },
};
</script>

<style scoped>
</style>
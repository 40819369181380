<template>

<div>


<CreateMitglieder :title='message' :speichern="speichern" :abbrechen="abbrechen" :bearbeiten="bearbeiten"/>

</div>

    

</template>


<script>
import CreateMitglieder from '../components/CreateMitglieder'


export default {
 name: 'MitgliederDetail',
     data(){
         return {
             message:"Mitglied Informationen",
             speichern:"Änderungen speichern",
             abbrechen:"Zurück zur Mitgliederübersicht",
             bearbeiten:true
         }
     },
     components: {
         CreateMitglieder
     },

}

     
</script>



<style scoped>

</style>
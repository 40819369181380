<template>
  <div>
    <div v-if="project">
      <div class="w-full lg:w-10/12 px-4 mx-auto mt-6">
        <div
          class="
            relative
            flex flex-col
            min-w-0
            break-words
            w-full
            mb-6
            shadow-lg
            rounded-lg
            bg-gray-100
            border-0
          "
        >
          <div class="rounded-t bg-white mb-0 px-6 py-6 bg-gray-100">
            <div class="text-center flex justify-between">
              <h6 class="text-blueGray-700 text-xl font-bold">
                Projekt {{ project.Projektnummer }}
              </h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="relative">
              <div class="absolute top-0 right-0">
 
                  <button
                    v-if="showProjektBearbeiten"
                    @click="onProjektBearbeiten()"
                    class="
                      bg-green-600
                      hover:bg-green-500
                      text-white text-xs
                      hidden
                      md:table-cell
                      font-bold
                      mx-1
                      py-2
                      px-4
                      rounded
                      inline-flex
                      items-center
                      uppercase
                    "
                  >
                    <span>Projekt bearbeiten</span>
                  </button>
                  <button
                    @click="triggerPopupLoeschenProjekt"
                    class="
                      bg-red-600
                      hover:bg-red-500
                      text-white text-xs
                      font-bold
                      hidden
                      lg:table-cell
                      mx-1
                      py-2
                      px-4
                      rounded
                      inline-flex
                      items-center
                      uppercase
                    "
                  >
                    <span>Projekt löschen</span>
                  </button>
                </div>
              
              <!-- Projekt bearbeiten Form -->
              <Form @submit="updateProjekt" :validation-schema="projektSchema">
                <fieldset id="projektForm" disabled="true">
                  <div class="flex justify-between">
                    <div
                      class="
                        text-blueGray-400 text-sm
                        mt-3
                        mb-6
                        font-bold
                        uppercase
                      "
                    >
                      Projekt Informationen
                    </div>
                    <div
                      v-if="showProjektInBearbeitung"
                      class="
                        lg:text-lg
                        font-bold
                        text-green-700
                        lg:mr-36
                        mr-1
                        text-base
                      "
                    >
                      Projekt kann jetzt bearbeitet werden!
                    </div>
                    <div></div>
                  </div>

                  <!-- Wrapper für Projektinformationen-Bereich -->
                  <div class="flex flex-wrap">
                    <!-- Projektname Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Projektname
                        </label>

                        <Field
                          type="text"
                          name="projektName"
                          id="projektName"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.Name"
                        />
                        <ErrorMessage
                          name="projektName"
                          class="error-feedback"
                        />
                      </div>
                    </div>
                    <!-- Projektnummer Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Projektnummer
                        </label>
                        <Field
                          type="text"
                          name="projektNummer"
                          id="projektNummer"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.Projektnummer"
                        />
                        <ErrorMessage
                          name="projektNummer"
                          class="error-feedback"
                        />
                      </div>
                    </div>
                    <!-- Projektverantwortliche/r Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Projektverantwortliche/r Name
                        </label>
                        <Field
                          type="text"
                          name="projektVerantwortlicher"
                          id="projektVerantwortlicher"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.VerantwortlicherName"
                        />
                        <ErrorMessage
                          name="projektVerantwortlicher"
                          class="error-feedback"
                        />
                      </div>
                    </div>
                    <!-- Projektverantwortliche/r Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Projektverantwortliche/r Vorname
                        </label>
                        <Field
                          type="text"
                          name="projektVerantwortlicherVorname"
                          id="projektVerantwortlicherVorname"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.VerantwortlicherVorname"
                        />
                        <ErrorMessage
                          name="projektVerantwortlicher"
                          class="error-feedback"
                        />
                      </div>
                    </div>
                    <!-- Projekt Eingangsdatum Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-4">
                        <label class="block uppercase text-xs font-bold mb-2">
                          Eingangsdatum des Projektes
                        </label>
                        <Field
                          type="date"
                          name="eingangsdatum"
                          class="
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            bg-white
                            rounded
                            text-sm
                            shadow-md
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.Eingangsdatum"
                        />
                        <ErrorMessage
                          name="eingangsdatum"
                          class="error-feedback"
                        />
                      </div>
                    </div>
                    <!-- Projektjahr Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Jahr
                        </label>

                        <Field
                          type="number"
                          name="projektJahr"
                          id="projektJahr"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.Jahr"
                        />
                        <ErrorMessage
                          name="projektJahr"
                          class="error-feedback"
                        />
                      </div>
                    </div>
                    <!-- Projekt Land Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Land
                        </label>
                        <div class="dropdown">
                          <input
                            v-if="Object.keys(selectedLand).length === 0"
                            @focus="changeDropdownVisibility"
                            @blur="changeDropdownVisibility"
                            ref="dropdowninput"
                            v-model.trim="landSearchString"
                            class="
                              self-start
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            type="text"
                            placeholder="Länder durchsuchen ..."
                          />
                          <div
                            v-else
                            @click="resetLand"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-gray-500
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                          >
                            <div class="flex flex-row items-center">
                              <span>{{ selectedLand.name }}</span>
                            </div>
                          </div>
                          <div
                            v-show="showLandDropdown"
                            class="dropdown-list z-20"
                          >
                            <div
                              @click="selectItem(item)"
                              v-show="itemVisible(item)"
                              v-for="item in laenderListe"
                              :key="item.name"
                              class="dropdown-item"
                            >
                              <div class="flex flex-row items-center">
                                <span>{{ item.name }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Projekt Kontinent Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label class="block uppercase text-xs font-bold mb-2">
                          Kontinent
                        </label>
                        <select
                          class="
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            bg-white
                            rounded
                            text-sm
                            shadow-md
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.land.Kontinent"
                        >
                          <option>Asien</option>
                          <option>Afrika</option>
                          <option>Nordamerika</option>
                          <option>Mittelamerika</option>
                          <option>Südamerika</option>
                          <option>Europa</option>
                          <option>Australien</option>
                        </select>
                      </div>
                    </div>

                    <!--Projekt aus dem Ausland -->
                    <div class="lg:w-3/12 flex">
                      <div class="w-full lg:w-6/12 px-4">
                        <div class="mt-2">
                          <div>
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                            Projekt in:
                            </label>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioAusland"
                                value="false"
                                
                                v-model="project.IsAusland"
                              />
                              <span class="ml-2">Schweiz</span>
                            </label>
                          </div>
                          <div>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioAusland"
                                value="true"
                  
                                v-model="project.IsAusland"
                              />
                              <span class="ml-2">Ausland</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <!--Projekt Archivieren -->
                      <div class="w-full lg:w-6/12 px-4">
                        <div class="mt-2">
                          <div>
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Projekt archivieren?
                            </label>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radio"
                                value="true"
                                checked
                                v-model="project.Archiviert"
                              />
                              <span class="ml-2">Ja</span>
                            </label>
                          </div>
                          <div>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radio"
                                value="false"
                                v-model="project.Archiviert"
                              />
                              <span class="ml-2">Nein</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="mt-6 border-b-1 border-gray-400" />

                  <h6
                    class="
                      text-blueGray-400 text-sm
                      mt-3
                      mb-6
                      font-bold
                      uppercase
                    "
                  >
                    Dokumente
                  </h6>
                  <!-- Wrapper für Dokumente Section des Projektes -->
                  <div class="flex flex-wrap">
                    <div class="w-full lg:w-3/12 px-3">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            textfeld
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                          >Antragsformular Upload (PDF oder Word)</label
                        >
                        <input
                          id="antragsformular"
                          type="file"
                          accept=".pdf , .docx"
                          class="
                            border-0
                            px-3
                            py-2.5
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                        />
                      </div>
                    </div>

                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Antragsformular Download
                        </label>
                        <div class="flex flex-row">
                        <div>
                        <p
                          v-if="antragsformularInfo"
                          class="text-red-800 font-semibold mt-5"
                        >
                          Kein Antragsformular erfasst
                        </p>
                        
                        <a
                          v-if="antragsformularDownload"
                          v-bind:href="project.Antragsformular"
                          v-bind:download="
                            project.AntragsformularFileName
                          "
                          class="
                            bg-green
                            hover:bg-grey
                            text-grey-darkest
                            font-bold
                            py-5
                            rounded
                            inline-flex
                            items-center
                          "
                        >
                    

                          <span class="underline
                                text-blue-600
                                hover:text-blue-800
                                visited:text-purple-600">{{
                            project.AntragsformularFileName
                          }}</span>

            
                        </a>
                        </div>
                           <!-- Papierkorb für löschen von Files-->
                          <div>
                          <button type="button" @click= formularLoeschen(this.antragsformular)>
                           <svg v-if="antragsformularDownload && !showProjektBearbeiten"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 text-red-600 ml-5 mt-5 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              > <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                /> </svg>
                          </button>
                          </div>
                       
                        </div>
                      </div>
                    </div>
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            textfeld
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                          >Schlussbericht Upload (PDF oder Word)</label
                        >
                        <input
                          id="schlussbericht"
                          type="file"
                          accept=".pdf , .docx"
                          class="
                            border-0
                            px-3
                            py-2.5
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                        />
                      </div>
                    </div>

                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3 ">
                        
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Schlussbericht Download
                        </label>
                        <div class="flex flex-row">
                        <div>
                        <p
                          v-if="schlussberichtInfo"
                          class="text-red-800 font-semibold mt-5"
                        >
                          Kein Schlussbericht erfasst
                        </p>
                        <a
                          v-if="schlussberichtDownload"
                          v-bind:href="project.Schlussbericht"
                          v-bind:download="
                            project.SchlussberichtFileName
                          "
                          class="
                            bg-green
                            font-bold
                            py-5
                            rounded
                            inline-flex
                            items-center
                          "
                        >
                         
                          <span class="underline
                                text-blue-600
                                hover:text-blue-800
                                visited:text-purple-600">{{
                            project.SchlussberichtFileName
                          }}</span>
                        </a>
                        </div>
                         <!-- Papierkorb für löschen von Files-->
                          <div>
                          <button type="button" @click= formularLoeschen(this.schlussbericht)>
                           <svg v-if="schlussberichtDownload && !showProjektBearbeiten"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 text-red-600 ml-5 mt-5 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              > <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                /> </svg>
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="mt-6 border-b-1 border-gray-400" />

                  <h6
                    class="
                      text-blueGray-400 text-sm
                      mt-3
                      mb-6
                      font-bold
                      uppercase
                    "
                  >
                    Projektträger / Kontaktperson Informationen
                  </h6>
                  <!-- Wrapper für Kontaktperson / Projektträger -->
                  <div class="flex flex-wrap">
                    <!-- Organisation Name Input -->
                    <div class="w-full lg:w-6/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Organisation
                        </label>
                        <Field
                          id="organisation"
                          name="organisation"
                          type="text"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.kontaktperson.Organisation"
                        />
                        <ErrorMessage
                          name="organisation"
                          class="error-feedback"
                        />
                      </div>
                    </div>
                    <!-- Nachname Projektträger Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Nachname
                        </label>
                        <Field
                          id="name"
                          name="name"
                          type="text"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.kontaktperson.Name"
                        />
                        <ErrorMessage name="name" class="error-feedback" />
                      </div>
                    </div>
                    <!-- Vorname Projektträger Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Vorname
                        </label>
                        <Field
                          id="vorname"
                          name="vorname"
                          type="text"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.kontaktperson.Vorname"
                        />
                        <ErrorMessage name="vorname" class="error-feedback" />
                      </div>
                    </div>
                    <!-- Adresse Projektträger Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Adresse
                        </label>
                        <Field
                          type="text"
                          name="adresse"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.kontaktperson.Adresse"
                        />
                        <ErrorMessage name="adresse" class="error-feedback" />
                      </div>
                    </div>
                    <!-- PLZ Projektträger Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          PLZ
                        </label>
                        <Field
                          type="text"
                          name="plz"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.kontaktperson.PLZ"
                        />
                        <ErrorMessage name="plz" class="error-feedback" />
                      </div>
                    </div>
                    <!-- Ort Projektträger Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Ort
                        </label>
                        <Field
                          type="text"
                          name="ort"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.kontaktperson.Ort"
                        />
                        <ErrorMessage name="ort" class="error-feedback" />
                      </div>
                    </div>
                    <!-- Land Projektträger Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Land
                        </label>
                        <div class="dropdown">
                          <input
                            v-if="
                              Object.keys(selectedLandOrganisation).length === 0
                            "
                            @focus="changeDropdownVisibilityOrganisation"
                            @blur="changeDropdownVisibilityOrganisation"
                            ref="dropdowninputOrganisation"
                            v-model.trim="landSearchStringOrganisation"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            type="text"
                            placeholder="Länder durchsuchen ..."
                          />
                          <div
                            v-else
                            @click="resetLandOrganisation"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-gray-500
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                          >
                            <div class="flex flex-row items-center">
                              <span>{{ selectedLandOrganisation.name }}</span>
                            </div>
                          </div>
                          <div
                            v-show="showLandDropdownOrganisation"
                            class="dropdown-list z-20"
                          >
                            <div
                              @click="selectItemOrganisation(item)"
                              v-show="itemVisibleOrganisation(item)"
                              v-for="item in laenderListeOrganisation"
                              :key="item.name"
                              class="dropdown-item"
                            >
                              <div class="flex flex-row items-center">
                                <span>{{ item.name }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Email Projektträger Input -->
                    <div class="w-full lg:w-3/12 px-4 mb-3">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          E-Mail
                        </label>
                        <Field
                          name="email"
                          id="email"
                          type="email"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.kontaktperson.Email"
                        />
                        <ErrorMessage name="email" class="error-feedback" />
                      </div>
                    </div>
                    <!-- Website Projektträger Input -->
                    <div class="w-full lg:w-3/12 px-4">
                      <div class="relative w-full mb-3">
                        <label
                          class="
                            block
                            uppercase
                            text-blueGray-600 text-xs
                            font-bold
                            mb-2
                          "
                        >
                          Website
                        </label>
                        <Field
                          type="text"
                          name="website"
                          class="
                            textfeld
                            border-0
                            px-3
                            py-3
                            placeholder-blueGray-300
                            text-blueGray-600
                            bg-white
                            rounded
                            text-sm
                            shadow
                            focus:outline-none focus:ring
                            w-full
                            ease-linear
                            transition-all
                            duration-150
                          "
                          v-model="project.kontaktperson.Website"
                        />
                        <ErrorMessage name="website" class="error-feedback" />
                      </div>
                    </div>

                    <!-- Cancel Button für Projekt -->
                    <div
                      v-if="showProjektInBearbeitung"
                      class="absolute bottom-0 right-0"
                    >
                      <button
                        id="buttonVerwerfen"
                        @click="onVerwerfenProjekt()"
                        class="
                          mb-0
                          bg-red-700
                          hover:bg-red-600
                          text-white
                          font-bold
                          uppercase
                          text-xs
                          px-4
                          py-2
                          rounded
                          shadow
                          hover:shadow-md
                          outline-none
                          focus:outline-none
                          ease-linear
                          transition-all
                          duration-150
                          mr-52
                        "
                        type="button"
                      >
                        Änderungen Verwerfen
                      </button>
                    </div>

                    <!-- Submit Button für Projekt -->
                    <div
                      v-if="showProjektInBearbeitung"
                      class="absolute bottom-0 right-0"
                    >
                      <button
                        id="buttonSpeichern"
                        class="
                          mb-0
                          bg-green-700
                          hover:bg-green-600
                          text-white
                          font-bold
                          uppercase
                          text-xs
                          px-4
                          py-2
                          rounded
                          shadow
                          hover:shadow-md
                          outline-none
                          focus:outline-none
                          ease-linear
                          transition-all
                          duration-150
                          mr-1
                        "
                        type="submit"
                      >
                        Änderungen Speichern
                      </button>
                    </div>
                  </div>
                </fieldset>
              </Form>
            </div>

            <!-- DIV Zahlungsinformationen => Neue Zahlung Buttons -->
            <div class="relative">
              <hr class="mt-6 border-b-1 border-gray-400" />
              <h6
                class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"
              >
                Zahlungsinformationen
              </h6>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-10/12 px-4"></div>

                <div
                  v-if="showButtonNeueZahlung"
                  class="absolute top-0 right-0 mt-5"
                >
                  <button
                    @click="triggerPopupNeueZahlung()"
                    class="
                      bg-green-600
                      hover:bg-green-500
                      text-white text-xs
                      font-bold
                      py-2
                      px-5
                      hidden
                      md:table-cell
                      rounded
                      inline-flex
                      items-center
                      uppercase
                    "
                  >
                   
                    <span>Neue Zahlung</span>
                  </button>
                </div>
              </div>
            </div>

            <!-- Zahlungen Tabelle -->
            <div class="tabelle flex flex-col pt-3">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                  class="
                    py-2
                    align-middle
                    inline-block
                    min-w-full
                    sm:px-6
                    lg:px-8
                  "
                >
                  <div
                    class="
                      shadow
                      overflow-hidden
                      border-b border-gray-200
                      sm:rounded-lg
                    "
                  >
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="
                              px-4
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Betrag
                          </th>
                          <th
                            scope="col"
                            class="
                              px-4
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Entschieden am
                          </th>
                          <th
                            scope="col"
                            class="
                              px-4
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Status
                          </th>
                          
                          <th
                            scope="col"
                            class="
                              px-4
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Belegsnummer
                          </th>
                          <th
                            scope="col"
                            class="
                              px-4
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Bezahlt am
                          </th>
                          <th
                            scope="col"
                            class="
                              px-4
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Bemerkung
                          </th>
                          <th
                            scope="col"
                            class="
                              px-4
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          >
                            Zwischenbericht Download
                          </th>
                          <th
                            scope="col"
                            class="
                              px-4
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          ></th>
                          <th
                            scope="col"
                            class="
                              px-4
                              py-3
                              text-left text-xs
                              font-medium
                              text-gray-500
                              uppercase
                              tracking-wider
                            "
                          ></th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="zahlung in project.zahlungen"
                        :key="zahlung.id"
                        class="
                          project
                          bg-white
                          divide-y divide-gray-200
                          text-gray-800 text-sm
                          whitespace-nowrap
                        "
                      >
                        <tr>
                          <td class="px-4 py-4">
                            <div>
                              {{ zahlung.Betrag }} {{ zahlung.Waehrung }}
                            </div>
                          </td>
                          <td class="px-4 py-4">
                            <div>
                              {{ zahlung.EntschiedenAm }}
                            </div>
                          </td>
                          <td class="px-4 py-4">
                            <span
                              v-bind:class="zahlung.Status"
                              class="
                                px-2
                                inline-flex
                                text-xs
                                leading-5
                                font-semibold
                                rounded-full
                                nicht-bearbeitet-status
                              "
                            >
                              {{ zahlung.Status }}
                            </span>
                          </td>
                          <td class="px-4 py-4">
                            {{ zahlung.BelegNr }}
                          </td>
                          <td class="px-4 py-4">
                            {{ zahlung.DatumZahlung }}
                          </td>
                          <td class="px-4 py-4">
                            {{ zahlung.Bemerkung }}
                          </td>
                          <td class="px-4 py-4">
                            <span v-if="zahlung.Zwischenbericht == ''"
                              >Kein Dokument erfasst</span
                            >
                            <div class="flex flex-row">
                            <div>
                            <a
                              class="
                                underline
                                text-blue-600
                                hover:text-blue-800
                                visited:text-purple-600
                              "
                              v-if="zahlung.Zwischenbericht != ''"
                              v-bind:href="zahlung.Zwischenbericht"
                              v-bind:download="zahlung.ZwischenberichtFileName"
                              >{{ zahlung.ZwischenberichtFileName }}</a
                            >
                            </div>
                            <div>
                            <svg v-if="zahlung.Zwischenbericht != '' && !stifteUndPapierkorb" @click="zwischenberichtLoeschen()" id="papierkorb"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5 text-red-600 ml-3 cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                              <svg v-if="zahlung.Zwischenbericht != '' && stifteUndPapierkorb "
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5 text-gray-400 ml-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </div>
                            </div>
                          </td>
                          <td class="px-2 py-4 ">
                            <button v-if="!stifteUndPapierkorb" id="btnStift"
                              @click="triggerPopupBearbeiten(zahlung)"
                              class="w-6 h-8 hidden lg:table-cell"
                            >
                              <svg id="stift"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 text-yellow-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                            <button v-if="stifteUndPapierkorb"
                              @click="triggerPopupBearbeiten(zahlung)"
                              class="w-6 h-8 hidden lg:table-cell pointer-events-none"
                            >
                              <svg id="stift"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </button>
                          </td>
                          <td class="px-2 py-4 ">
                            <button v-if="!stifteUndPapierkorb"  id="btnPapierkorb"
                              @click="triggerPopupLoeschen(zahlung)"
                              class="w-6 h-8 hidden lg:table-cell"
                            >
                              <svg id="papierkorb"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 text-red-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                            <button  v-if="stifteUndPapierkorb"
                              @click="triggerPopupLoeschen(zahlung)"
                              class="w-6 h-8 hidden lg:table-cell pointer-events-none "
                            >
                              <svg id="papierkorb"
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!-- Button zurück zur Projektübersicht -->
            <button
              v-if="showButtonZurueckzuProjekten"
              @click="onBack()"
              class="
                text-xs
                mt-8
                bg-green-600
                hover:bg-green-500
                text-white
                font-bold
                py-2
                px-4
                rounded
                inline-flex
                items-center
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
                />
              </svg>
              <span class="uppercase">Zurück zur Projektübersicht</span>
            </button>
          </div>
        </div>
      </div>

      <!-- Pop UP Zahlung bearbeiten-->
      <div
        v-if="showPopupBearbeiten"
        class="
          popup
          bg-black
          fixed
          inset-0
          flex
          items-center
          justify-center
          bg-opacity-70
          text-black
        "
      >
        <div class="bg-white p-16 rounded-md relative w-9/12">
          <!-- Zahlungs Popup schliessen (X) Button -->
          <div class="absolute top-0 right-0">
            <button
              @click="triggerZahlungsPopUpAnzeige()"
              class="
                inline-flex
                items-center
                justify-center
                w-10
                h-10
                mr-3
                mt-3
                text-white
                transition-colors
                duration-150
                bg-red-400
                rounded-lg
                focus:shadow-outline
                hover:bg-red-300
              "
            >
              X
            </button>
          </div>
          <h6 class="text-sm mt-3 mb-3 font-bold uppercase">
            Zahlung bearbeiten
          </h6>
          <!-- Zahlung bearbeiten Form -->
          <Form @submit="onZahlungUpdaten" class="bg-white" >
            <hr class="mt-6 border-b-1 border-blueGray-300" />

            <div class="flex flex-wrap mt-3">
              <!-- Zahlung Betrag Input -->
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Betrag
                  </label>
                  <Field
                    name="betrag"
                    type="number"
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="aktuelleZahlung.Betrag"
                  />
                  <ErrorMessage name="betrag" class="error-feedback" />
                </div>
              </div>
              <!-- Währung Input -->
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Währung
                  </label>
                  <select
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="aktuelleZahlung.Waehrung"
                  >
                    <option>CHF</option>
                    <option>USD</option>
                    <option>Euro</option>
                  </select>
                </div>
              </div>
              
              <!-- Status Input -->
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-4">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                  >
                    Status
                  </label>
                  <select
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      text-blueGray-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="aktuelleZahlung.Status"
                  >
                    <option>Erfasst</option>
                    <option>Bewilligt</option>
                    <option>Abgelehnt</option>
                    <option>Bezahlt</option>
                    <option>Nicht bearbeitet</option>
                    <option>Abgeschrieben</option>
                    <option>Ausstehend</option>

                  </select>
                </div>
              </div>
              <!-- EntschiedenAm Input -->
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-4">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Entschieden am
                  </label>
                  <Field
                    type="date"
                    name="entschiedenAm"
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="aktuelleZahlung.EntschiedenAm"
                  />
                  <ErrorMessage name="entschiedenAm" class="error-feedback" />
                </div>
              </div>
              <!-- Belegsnummer Input -->
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-4">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Belegsnummer
                  </label>
                  <Field
                    name="belegsnummer"
                    type="number"
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="aktuelleZahlung.BelegNr"
                  />
                  <ErrorMessage name="belegsnummer" class="error-feedback" />
                </div>
              </div>
              <!-- Zahlungsdatum Input -->
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-4">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Bezahlt am
                  </label>
                  <Field
                    type="date"
                    name="zahlungsDatum"
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="aktuelleZahlung.DatumZahlung"
                  />
                  <ErrorMessage name="zahlungsDatum" class="error-feedback" />
                </div>
              </div>
              <!-- Zwischenbericht Upload Input -->
              <div class="w-full lg:w-4/12 px-4">
                <div class="relative w-full mb-4">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Upload Zwischenbericht (PDF oder Word)
                  </label>
                  <input
                    id="zwischenbericht"
                    type="file"
                    accept=".pdf , .docx"
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                  />
                </div>
              </div>
              <!-- Bemerkung Input -->
              <div class="w-full lg:w-8/12 px-4">
                <div class="relative w-full mb-4">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Bemerkung
                  </label>
                  <Field
                    type="text"
                    name="bemerkung"
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="aktuelleZahlung.Bemerkung"
                  />
                  <ErrorMessage name="bemerkung" class="error-feedback" />
                </div>
              </div>
            </div>
            <div class="absolute bottom-0 right-0">
            <button
              class="
                bg-green-600
                hover:bg-green-500
                mr-20
                mb-5
                text-white
                font-bold
                uppercase
                text-xs
                px-6
                py-3
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              "
              type="submit"
            >
              Speichern
            </button>
          </div>
          </Form>
          
        </div>
      </div>

      <!-- Pop UP Formular löschen-->
      
      <div
        v-if="showPopupFormularLoeschen"
        class="
          popup
          bg-black
          fixed
          inset-0
          flex
          items-center
          justify-center
          bg-opacity-70
          text-black
        "
      >
        <div class="bg-white p-12 rounded-md relative">
          Möchten Sie den Zwischenbericht wirklich löschen?

          <div class="mt-2">
            <button
              id="buttonVerwerfen"
              @click="triggerFormularPopupLoeschen()"
              class="
                mr-24
                mb-0
                bg-red-700
                hover:bg-red-600
                text-white
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Abbrechen
            </button>

            <button
              @click="onFormularLoeschen()"
              class="
                mb-0
                bg-green-700
                hover:bg-green-600
                text-white
                font-bold
                uppercase
                text-xs
                px-12
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Ja
            </button>
          </div>
        </div>
      </div>

      <!-- Pop UP Zahlung löschen-->
      <div
        v-if="showPopupLoeschen"
        class="
          popup
          bg-black
          fixed
          inset-0
          flex
          items-center
          justify-center
          bg-opacity-70
          text-black
        "
      >
        <div class="bg-white p-12 rounded-md relative">
          Möchtest du die Zahlung wirklich löschen?

          <div class="mt-2">
            <button
              id="buttonVerwerfen"
              @click="triggerPopupLoeschen()"
              class="
                mr-24
                mb-0
                bg-red-700
                hover:bg-red-600
                text-white
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Abbrechen
            </button>

            <button
              @click="onZahlungLoeschen(this.aktuelleZahlung)"
              class="
                mb-0
                bg-green-700
                hover:bg-green-600
                text-white
                font-bold
                uppercase
                text-xs
                px-12
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Ja
            </button>
          </div>
        </div>
      </div>

      <!-- Pop UP Zahlung erfassen-->
      <div
        v-if="showPopupZahlungErfassen"
        class="
          popup
          bg-black
          fixed
          inset-0
          flex
          items-center
          justify-center
          bg-opacity-70
          text-black
        "
      >
        <div class="bg-white p-16 rounded-md relative">
          <!-- PopUp Schliessen (X) Button -->
          <div class="absolute top-0 right-0">
            <button
              @click="triggerPopupNeueZahlung()"
              class="
                inline-flex
                items-center
                justify-center
                w-10
                h-10
                mr-3
                mt-3
                text-white
                transition-colors
                duration-150
                bg-red-400
                rounded-lg
                focus:shadow-outline
                hover:bg-red-300
              "
            >
              X
            </button>
          </div>

          <h6 class="text-sm mt-3 mb-3 font-bold uppercase">
            Neue Zahlung erfassen
          </h6>

          <!-- Neue Zahlung Form -->
          <Form class="bg-white">
            <hr class="mt-6 border-b-1 border-blueGray-300" />
            <div class="flex flex-wrap mt-3">
              <!-- Betrag neue Zahlung Input -->
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Betrag
                  </label>
                  <Field
                    name="neueZahlungBetrag"
                    type="number"
                    class="
                      text-black
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="neueZahlung.Betrag"
                  />
                  <ErrorMessage
                    name="neueZahlungBetrag"
                    class="error-feedback"
                  />
                </div>
              </div>
              <!-- Währung neue Zahlung Input -->
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Währung
                  </label>
                  <select
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="neueZahlung.Waehrung"
                  >
                    <option>CHF</option>
                    <option>US-Dollar</option>
                    <option>Euro</option>
                  </select>
                </div>
              </div>
              
              <!-- Status neue Zahlung Input -->
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label
                    class="
                      block
                      uppercase
                      text-blueGray-600 text-xs
                      font-bold
                      mb-2
                    "
                  >
                    Status
                  </label>
                  <select
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      text-blueGray-600
                      bg-white
                      rounded
                      text-sm
                      shadow
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="neueZahlung.Status"
                  >
                    <option>Erfasst</option>
                    <option>Bewilligt</option>
                    <option>Abgelehnt</option>
                    <option>Bezahlt</option>
                    <option>Ausstehend</option>
                    <option>Nicht bearbeitet</option>
                    <option>Abgeschrieben</option>
                  </select>
                </div>
              </div>
              <!-- EntschiedenAm neue Zahlung Input -->
              <div class="w-full lg:w-6/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Entschieden am
                  </label>
                  <Field
                    type="date"
                    name="entschiedenAmNeueZahlung"
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="neueZahlung.EntschiedenAm"
                  />
                  <ErrorMessage
                    name="entschiedenAmNeueZahlung"
                    class="error-feedback"
                  />
                </div>
              </div>
              <!-- Bemerkung neue Zahlung Input -->
              <div class="w-full lg:w-12/12 px-4">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-xs font-bold mb-2">
                    Bemerkung
                  </label>
                  <Field
                    type="text"
                    name="bemerkungNeueZahlung"
                    class="
                      border-0
                      px-3
                      py-3
                      placeholder-blueGray-300
                      bg-white
                      rounded
                      text-sm
                      shadow-md
                      focus:outline-none focus:ring
                      w-full
                      ease-linear
                      transition-all
                      duration-150
                    "
                    v-model="neueZahlung.Bemerkung"
                  />
                  <ErrorMessage
                    name="bemerkungNeueZahlung"
                    class="error-feedback"
                  />
                </div>
              </div>
            </div>
          </Form>

          <!-- Neue Zahlung speichern -->
          <div class="absolute bottom-0 right-0">
            <button
              @click="
                onSpeichernNeueZahlung(
                  this.neueZahlung,
                  this.project.id
                )
              "
              class="
                bg-green-600
                hover:bg-green-500
                mr-20
                mb-5
                text-white
                font-bold
                uppercase
                text-xs
                px-6
                py-3
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Speichern
            </button>
          </div>
        </div>
      </div>

      <!-- Pop UP Projektänderung werden gespeichert-->
      <div
        v-if="showPopupSpeichern"
        class="
          bg-black
          fixed
          inset-0
          flex
          items-center
          justify-center
          bg-opacity-70
          text-black
        "
      >
        <div class="bg-white p-12 rounded-md relative">
          <div v-if="!projektServerResponse">
            {{ aenderungsPopUpText }}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-10 w-10 ml-28 animate-spin mt-5 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </div>
          <div class="form-group mt-3">
            <div v-if="projektServerResponse" role="alert">
              <div
                class="
                  bg-red-500
                  text-white
                  font-bold
                  rounded-t
                  px-4
                  py-2
                  text-center
                "
              >
                Leider ist ein Fehler aufgetreten.
              </div>
              <div
                class="
                  border border-t-0 border-red-400
                  rounded-b
                  bg-red-100
                  px-4
                  py-3
                  text-red-700
                "
              >
                <p class="text-center">Folgender Fehler ist aufgetreten:</p>
                <p class="text-center font-bold">{{ projektServerResponse }}</p>
                <p>
                  Versuchen Sie es später erneut, oder melden Sie den Fehler dem
                  Administrator.
                </p>
                <p>
                  Bitte warten Sie ca. 30 Sekunden, bis Sie die Seite neu Laden
                  und zum Projekt zurückkehren
                </p>
              </div>
              <button
                @click="reloadPage"
                class="
                  bg-green-600
                  hover:bg-green-500
                  mr-20
                  mb-5
                  text-white
                  font-bold
                  uppercase
                  w-full
                  mt-5
                  text-xs
                  px-6
                  py-3
                  rounded
                  shadow
                  hover:shadow-md
                  outline-none
                  focus:outline-none
                  mr-1
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                Zurück zum Projekt
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>Laden des Projekts</div>
    <div v-if="showPopupLoeschenProjekt" class="popup bg-black fixed inset-0 flex items-center justify-center z-30 bg-opacity-70 text-black">
        <div class="bg-white px-24 py-12 rounded-md relative ">

          Möchtest du das Projekt <br><strong> {{ this.project.Name }} </strong> <br> für immer löschen?

          <div v-if="showConfirmMessage" class= "mt-4">

          <div class= "absolute bottom-0 left-0 mt-10">
          
              <button id="buttonVerwerfen" @click="triggerPopupLoeschenProjekt()"  class=" mb-5 ml-12  bg-red-700 hover:bg-red-600 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150" type="button">
                  Abbrechen
              </button>  
            
          </div>

          <div class= "absolute bottom-0 right-0">
              <button id="buttonLoeschen" @click="deleteProjekt()"  class="mb-5 mr-12 mt-5 bg-green-700 hover:bg-green-600 text-white font-bold uppercase text-xs px-12 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 " type="button">
                  Ja
              </button>  
          
          </div>
          </div> 

          <div v-if="!projektServerResponse && !showConfirmMessage">
            Das Projekt wird gelöscht ...

            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-10 w-10 ml-28 animate-spin mt-5 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </div>
          <div v-if="projektServerResponse" role="alert">
              <div
                class="
                  bg-red-500
                  text-white
                  font-bold
                  rounded-t
                  px-4
                  py-2
                  text-center
                "
              >
                Leider ist ein Fehler aufgetreten.
              </div>
              <div
                class="
                  border border-t-0 border-red-400
                  rounded-b
                  bg-red-100
                  px-4
                  py-3
                  text-red-700
                "
              >
                <p class="text-center">Folgender Fehler ist aufgetreten:</p>
                <p class="text-center font-bold">{{ projektServerResponse }}</p>
                <p>
                  Versuchen Sie es später erneut, oder melden Sie den Fehler dem
                  Administrator.
                </p>
                <p>
                  Bitte warten Sie ca. 30 Sekunden, bis Sie die Seite neu Laden
                  und zum Projekt zurückkehren
                </p>
              </div>
              <button
                @click="reloadPage"
                class="
                  bg-green-600
                  hover:bg-green-500
                  mr-20
                  mb-5
                  text-white
                  font-bold
                  uppercase
                  w-full
                  mt-5
                  text-xs
                  px-6
                  py-3
                  rounded
                  shadow
                  hover:shadow-md
                  outline-none
                  focus:outline-none
                  mr-1
                  ease-linear
                  transition-all
                  duration-150
                "
              >
                Zurück zur Projektübersicht
              </button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import dataService from "../services/dataService";
import jsonLaenderListe from "../assets/countries.json";

/* Forms und Validation libs importieren */
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "ProjectDetailForm",
  /* Komponenten für Form und Validierung */
  components: {
    Form,
    Field,
    ErrorMessage,
  },

  data() {
    /* Einstellungen der Form als Schema */
    const projektSchema = yup.object().shape({
      projektName: yup
        .string()
        .required("Der Projektname darf nicht leer sein!"),
      projektNummer: yup
        .string()
        .required("Die Projektnummer darf nicht leer sein!")
        .matches(
          /[0-9][0-9][0-9][0-9].[0-9][0-9]/,
          "Die Projektnummer muss im folgenden Format erfasst werden: XXXX.XX (Beispiel: 2021.01)"
        ),
      projektJahr: yup
        .number()
        .required("Das Projektjahr darf nicht leer sein!")
        .positive()
        .max(9999, "Bitte das Jahr im Format JJJJ eingeben (Bsp. 2021)")
        .min(1000, "Bitte das Jahr im Format JJJJ eingeben (Bsp. 2021)")
        .typeError("Das Projektjahr darf nicht leer sein!"),
      email: yup
        .string()
        .email("Bitte eine gültige Email Adresse eingeben")
        .nullable(),
    });

    return {
      /* Das Schema definiert die Einstellungen der Form */
      projektSchema,
      /* Message bzw. Error vom Server welche angezeigt werden kann */
      projektServerResponse: "",

      project: null,
      aktuelleZahlung: null,
      id: 0,
      showPopupZahlungErfassen: false,
      showPopupBearbeiten: false,
      showPopupLoeschen: false,
      showPopupLoeschenProjekt: false,
      showPopupArchivieren: false,
      showPopupSpeichern: false,
      showPopupFormularLoeschen: false,

      showProjektBearbeiten: true,
      showAenderungenVerwerfen: false,
      showAenderungenSpeichern: false,
      showProjektInBearbeitung: false,
      schlussberichtDownload: false,
      schlussberichtInfo: true,
      zwischenberichtDownload: true,
      antragsformularInfo: true,
      antragsformularDownload: false,
      showConfirmMessage: true,

      antragsformular: "antragsformular",
      schlussbericht: "schlussbericht",
      stifteUndPapierkorb: false,

      selectedLand: {},
      landSearchString: "",
      laenderListe: [],
      showLandDropdown: false,

      selectedLandOrganisation: {},
      landSearchStringOrganisation: "",
      showLandDropdownOrganisation: false,
      laenderListeOrganisation: [],

      showButtonNeueZahlung: true,
      showButtonZurueckzuProjekten: true,
      showButtonArchivieren: true,
      aenderungsPopUpText: "",

      neueZahlung: {
        Betrag: 0,
        Bemerkung: "",
        Status: "",
        Waehrung: "",
        EntschiedenAm: "",
      },
    };
  },

  mounted() {
    var id = this.$route.params.id;
    this.getLaenderList();
    this.getLaenderListOrganisation();
    this.getProjektDetail(id);
  },

  methods: {
    /* Methoden für die Landliste der Projekte */
    resetLand() {
      if (!this.showProjektBearbeiten) {
        this.selectedLand = {};
        this.$nextTick(() => this.$refs.dropdowninput.focus());
        this.$emit("on-item-reset");
      }
    },
    changeDropdownVisibility() {
      setTimeout(() => {
        if (this.selectedLand != {})
          this.showLandDropdown = !this.showLandDropdown;
      }, 200);
    },
    selectItem(theItem) {
      setTimeout(() => {
        this.selectedLand = theItem;
        this.landSearchString = "";
        this.$emit("on-item-selected", theItem);
        this.showLandDropdown = false;
      }, 200);
    },
    itemVisible(item) {
      let currentName = item.name.toLowerCase();
      let currentInput = this.landSearchString.toLowerCase();
      return currentName.startsWith(currentInput);
    },
    getLaenderList() {
      for (let index = 0; index < jsonLaenderListe.length; index++) {
        this.laenderListe.push(jsonLaenderListe[index]);
      }
    },

    /* Methoden für die Landliste von Organisationen */
    /* Methoden für die Landliste der Projekte */
    resetLandOrganisation() {
      if (!this.showProjektBearbeiten) {
        this.selectedLandOrganisation = {};
        this.$nextTick(() => this.$refs.dropdowninputOrganisation.focus());
        this.$emit("on-item-reset");
      }
    },
    changeDropdownVisibilityOrganisation() {
      setTimeout(() => {
        if (this.selectedLandOrganisation != {})
          this.showLandDropdownOrganisation =
            !this.showLandDropdownOrganisation;
      }, 200);
    },
    selectItemOrganisation(theItem) {
      setTimeout(() => {
        this.selectedLandOrganisation = theItem;
        this.landSearchStringOrganisation = "";
        this.$emit("on-item-selected", theItem);
        this.showLandDropdownOrganisation = false;
      }, 200);
    },
    itemVisibleOrganisation(item) {
      let currentName = item.name.toLowerCase();
      let currentInput = this.landSearchStringOrganisation.toLowerCase();
      return currentName.startsWith(currentInput);
    },
    getLaenderListOrganisation() {
      for (let index = 0; index < jsonLaenderListe.length; index++) {
        this.laenderListeOrganisation.push(jsonLaenderListe[index]);
      }
    },

    /* Weitere Methoden */
    onBack() {
      this.$router.push("/hilfsprojekte");
    },

    triggerPopupNeueZahlung() {
      this.showPopupZahlungErfassen = !this.showPopupZahlungErfassen;
    },

    triggerPopupBearbeiten(zahlung) {
      this.aktuelleZahlung = zahlung;
      this.aktuelleZahlung.Betrag = this.aktuelleZahlung.Betrag.replace(
        /[^0-9.]/g,
        ""
      );
      this.aktuelleZahlung.EntschiedenAm =
        this.aktuelleZahlung.EntschiedenAm.split(".").reverse().join("-");
      this.aktuelleZahlung.DatumZahlung =
        this.aktuelleZahlung.DatumZahlung.split(".").reverse().join("-");
      this.showPopupBearbeiten = !this.showPopupBearbeiten;
    },
    triggerZahlungsPopUpAnzeige() {
      window.location.reload();
      this.showPopupBearbeiten = !this.showPopupBearbeiten;
    },
    triggerPopupLoeschen(zahlung) {
      this.aktuelleZahlung = zahlung;
      this.showPopupLoeschen = !this.showPopupLoeschen;
    },
    reloadPage() {
      //Wider weg machen und neu laden
      window.location.reload();
    },

    /** Sammelt die Daten und gibt Sie an DataService weiter  */
    async updateProjekt() {
      /* Neues Projekt erstellen, welches dann mitgeschickt wird, damit nicht das gebindete verändert werden muss */
      var tempProjectForDataService = Object.assign({}, this.project);
      tempProjectForDataService.land = Object.assign({}, this.project.land);
      tempProjectForDataService.kontaktperson = Object.assign(
        {},
        this.project.kontaktperson
      );

      /*PopUp anzeigen */
      this.showPopupSpeichern = true;
      this.aenderungsPopUpText = "Änderungen werden gespeichert ...";

      /* Die hochgeladenen Dokumente und Filenamen holen */
      var schlussberichtDokument =
        document.getElementById("schlussbericht").files[0];
      var schlussberichtFileName =
        document.getElementById("schlussbericht").value;
      var antragsformularDokument =
        document.getElementById("antragsformular").files[0];
      var antragsformularFileName =
        document.getElementById("antragsformular").value;

      /* Falls Schlussbericht nicht leer ist, nach base64 konvertieren und in Projekt überschreiben */
      if (schlussberichtDokument != null) {
        var base64Schlussbericht = await this.fileToBase64(
          schlussberichtDokument
        );

        //Prüfungen ob es Fehler gab bei der Konvertierung nach Base64
        if (base64Schlussbericht instanceof Error) {
          console.log("Error: ", base64Schlussbericht.message);
        } else {
          tempProjectForDataService.Schlussbericht = base64Schlussbericht;
        }
        tempProjectForDataService.SchlussberichtFileName = this.getFilename(
          schlussberichtFileName
        );
      }

      /* Falls Antragsformular nicht leer ist, nach base64 konvertieren und in Projekt überschreiben */
      if (antragsformularDokument != null) {
        var base64Antragsformular = await this.fileToBase64(
          antragsformularDokument
        );

        //Prüfungen ob es Fehler gab bei der Konvertierung nach Base64
        if (base64Antragsformular instanceof Error) {
          console.log("Error: ", base64Antragsformular.message);
        } else {
          tempProjectForDataService.Antragsformular = base64Antragsformular;
        }
        tempProjectForDataService.AntragsformularFileName = this.getFilename(
          antragsformularFileName
        );
      }

      /* Das ausgewählte Land aus der Liste dem Projekt zuweisen */
      if (this.selectedLand != {}) {
        if (this.selectedLand.name === undefined) {
          tempProjectForDataService.land.Name = null;
        } else {
          tempProjectForDataService.land.Name = this.selectedLand.name;
        }
      } else {
        /* Es wurde kein Land und kein Kontinent ausgewählt */
        if (tempProjectForDataService.land.Kontinent == "") {
          tempProjectForDataService.land = null;
        }
      }

      /* Das ausgewählte Land der Organisationen der Kontaktperson zuweisen */
      if (this.selectedLandOrganisation != {}) {
        if (this.selectedLandOrganisation.name === undefined) {
          tempProjectForDataService.kontaktperson.Land = null;
        } else {
          tempProjectForDataService.kontaktperson.Land =
            this.selectedLandOrganisation.name;
        }
      } else {
        /* Es wurde kein Land ausgewählt */
        tempProjectForDataService.kontaktperson.Land = null;
      }

      /*leere kPerson als null senden */
      if (
        (tempProjectForDataService.kontaktperson.Name == "" ||
          tempProjectForDataService.kontaktperson.Name == null) &&
        (tempProjectForDataService.kontaktperson.Vorname == "" ||
          tempProjectForDataService.kontaktperson.Vorname == null) &&
        (tempProjectForDataService.kontaktperson.Email == "" ||
          tempProjectForDataService.kontaktperson.Email == null) &&
        (tempProjectForDataService.kontaktperson.Ort == "" ||
          tempProjectForDataService.kontaktperson.Ort == null) &&
        (tempProjectForDataService.kontaktperson.Organisation == "" ||
          tempProjectForDataService.kontaktperson.Organisation == null) &&
        (tempProjectForDataService.kontaktperson.PLZ == "" ||
          tempProjectForDataService.kontaktperson.PLZ == null) &&
        (tempProjectForDataService.kontaktperson.Website == "" ||
          tempProjectForDataService.kontaktperson.Website == null) &&
        (tempProjectForDataService.kontaktperson.Adresse == "" ||
          tempProjectForDataService.kontaktperson.Adresse == null) &&
        (tempProjectForDataService.kontaktperson.Land == "" ||
          tempProjectForDataService.kontaktperson.Land == null)
      ) {
        tempProjectForDataService.kontaktperson = null;
      }
      /* Daten an Dataservice schicken */
      /* Falls es failt, eine Error Message ausgeben */
      try {
        await dataService.updateProjekt(tempProjectForDataService);
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        this.projektServerResponse = error.message;
      }
    },

    /** Holt den Filenamen aus einem langen Pfad */
    getFilename(fullPath) {
      var startIndexSchluss =
        fullPath.indexOf("\\") >= 0
          ? fullPath.lastIndexOf("\\")
          : fullPath.lastIndexOf("/");
      var filenameSchluss = fullPath.substring(startIndexSchluss);
      if (
        filenameSchluss.indexOf("\\") === 0 ||
        filenameSchluss.indexOf("/") === 0
      ) {
        return filenameSchluss.substring(1);
      } else {
        return "";
      }
    },

    /** Konvertiert das mitgegebene File nach base64 */
    fileToBase64: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }),

    //---------------Zahlung updaten löschen und create----------------------------------------------------------------------------------------------

    //Button Zahlungbearbeiten und Änderungen speichern
    async onZahlungUpdaten() {

      var aktuelleZahlung = this.aktuelleZahlung;
      this.showPopupBearbeiten = false;
      this.showPopupSpeichern = true;
      this.aenderungsPopUpText = "Änderungen werden gespeichert ...";

      var zwischenBerichtDokument =
        document.getElementById("zwischenbericht").files[0];
      var zwischenBerichtFileName =
        document.getElementById("zwischenbericht").value;

      /* Falls Schlussbericht nicht leer ist, nach base64 konvertieren und in Projekt überschreiben */
      if (zwischenBerichtDokument != null) {
        var base64SZwischenBericht = await this.fileToBase64(
          zwischenBerichtDokument
        );

        //Prüfungen ob es Fehler gab bei der Konvertierung nach Base64
        if (base64SZwischenBericht instanceof Error) {
          console.log("Error: ", base64SZwischenBericht.message);
        } else {
          aktuelleZahlung.Zwischenbericht = base64SZwischenBericht;
        }
        aktuelleZahlung.ZwischenberichtFileName = this.getFilename(
          zwischenBerichtFileName
        );
      }

      try {
        await dataService.updateZahlung(aktuelleZahlung);
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        this.projektServerResponse = error.message;
      }
    },

    onVerwerfenProjekt() {
      //Seite neu laden
      window.location.reload();
    },

    async onSpeichernNeueZahlung(neueZahlung, projectId) {
      this.neueZahlung.projektId = projectId;
      this.aenderungsPopUpText = "Neue Zahlung wird abgespeichert ...";
      this.showPopupZahlungErfassen = false;
      this.showPopupSpeichern = true;
      try {
        await dataService.createZahlung(neueZahlung);
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        this.projektServerResponse = error.message;
      }
    },

    async onZahlungLoeschen(aktuelleZahlung) {
      //Zahlung Löschen
      var zahlung = aktuelleZahlung;

      this.showPopupLoeschen = false;
      this.showPopupSpeichern = true;
      this.aenderungsPopUpText = "Zahlung wird gelöscht ...";

      try {
        await dataService.deleteZahlung(zahlung);
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        this.projektServerResponse = error.message;
      }
    },

    async deleteProjekt() {
      this.showConfirmMessage = false;
      try {
        await dataService.deleteProjekt(this.project);
        this.$router.push("/hilfsprojekte");
      } catch (error) {
        console.log(error.message);
        this.projektServerResponse = error.message;
      }
    },
    triggerPopupLoeschenProjekt() {
      //Buttons unsichtbar machen
      this.showPopupLoeschenProjekt = !this.showPopupLoeschenProjekt;
    },

    formularLoeschen(info) {
      if (info == "antragsformular") {
        this.project.AntragsformularFileName = "";
        this.project.Antragsformular = "";
        this.antragsformularInfo = true;
        this.antragsformularDownload = false;
      }
      if (info == "schlussbericht") {
        this.project.SchlussberichtFileName = "";
        this.project.Schlussbericht = "";
        this.schlussberichtInfo = true;
        this.schlussberichtDownload = false;
      }
    },

    async onFormularLoeschen(){

      //Ladepopup zeigen, falls Serverresponse error Message hat, wird es dem Nutzer gezeigt
      this.showPopupSpeichern = true;
      this.showPopupFormularLoeschen = false;
      this.aenderungsPopUpText = "Zwischenbericht wird gelöscht ...";

      var aktuelleZahlung = this.aktuelleZahlung;

      //Zwischenbericht löschen
      aktuelleZahlung.Zwischenbericht="";
      aktuelleZahlung.ZwischenberichtFileName="";

      //Betrag umformen, da er noch 1000er Zeichen hat
      aktuelleZahlung.Betrag = aktuelleZahlung.Betrag.replace(
        /[^0-9.]/g,
        ""
      );

      try {
        await dataService.updateZahlung(aktuelleZahlung);
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        this.projektServerResponse = error.message;
      }

    },

    zwischenberichtLoeschen(){
      this.showPopupFormularLoeschen = true;

    },

    triggerFormularPopupLoeschen() {
      this.showPopupFormularLoeschen = !this.showPopupFormularLoeschen;
    },

    //-----------------------------------------------------------------------------------------------------------------------------------------

    onProjektBearbeiten() {
      //Sifte und Papierkorb grau einfärben
      
      this.stifteUndPapierkorb=true
    
  

      //Aktiviert die Input Felder
      document.getElementById("projektForm").disabled = false;

      //ButtonBearbeiten invisible
      this.showProjektBearbeiten = false;
      //Button Speichern Visible machen
      this.showAenderungenVerwerfen = true;
      //Button Verwerfen Visible machen
      this.showAenderungenSpeichern = true;
      //Text Dokument kann jetzt bearbeitet Visible machen
      this.showProjektInBearbeitung = true;
      //Button Neue Zahlung Invisible machen
      this.showButtonNeueZahlung = false;
      //Button Zurück zur Projektübersich Invisible machen
      this.showButtonZurueckzuProjekten = false;
      //Button Archivieren Invisible machen
      this.showButtonArchivieren = false;
    },

    async getProjektDetail(id) {
      const response = await dataService.getProjektDetail(id);

      /*Zuerst ein Helper-Projekt erstellen, in welchem Null Werte erstetzt werden. Erst am Schluss auf binding-Project kopieren */
      var helperProjekt = response.data;
      if (helperProjekt.land && helperProjekt.land.Name) {
        this.selectedLand = await this.laenderListe.find((land) => {
          return land.name == helperProjekt.land.Name;
        });
        //Falls kein offizielles Land => Neues Land nehmen
        if (!this.selectedLand) {
          let falseLand = {
            name: helperProjekt.land.Name,
          };
          this.selectedLand = falseLand;
        }
      }

      if (helperProjekt.kontaktperson && helperProjekt.kontaktperson.Land) {
        this.selectedLandOrganisation =
          await this.laenderListeOrganisation.find((land) => {
            return land.name == helperProjekt.kontaktperson.Land;
          });
        //Falls kein offizielles Land => Neues Land nehmen
        if (!this.selectedLandOrganisation) {
          let falseLand = {
            name: helperProjekt.kontaktperson.Land,
          };
          this.selectedLandOrganisation = falseLand;
        }
      }

      if (helperProjekt.zahlungen.length > 0) {
        this.aktuelleZahlung = helperProjekt.zahlungen[0];
      }

      for (let index = 0; index < helperProjekt.zahlungen.length; index++) {
        //Bezahlung in hochkomma Zahlen anzeigen
        if (
          helperProjekt.zahlungen[index].Betrag === undefined ||
          helperProjekt.zahlungen[index].Betrag == null ||
          helperProjekt.zahlungen[index].Betrag == ""
        ) {
          helperProjekt.zahlungen[index].Betrag = 0;
        }
        helperProjekt.zahlungen[index].Betrag = helperProjekt.zahlungen[
          index
        ].Betrag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");

        if(helperProjekt.zahlungen[index].Betrag.split('.')[1] != undefined && helperProjekt.zahlungen[index].Betrag.split('.')[1].length > 0 && 
        helperProjekt.zahlungen[index].Betrag.split('.')[1].length < 2) {
          helperProjekt.zahlungen[index].Betrag += "0";
        }
        
      }
      for (let index = 0; index < helperProjekt.zahlungen.length; index++) {
        //Datum richtig anzeigen lassen
        helperProjekt.zahlungen[index].EntschiedenAm = helperProjekt.zahlungen[
          index
        ].EntschiedenAm.split("-")
          .reverse()
          .join(".");
        helperProjekt.zahlungen[index].DatumZahlung = helperProjekt.zahlungen[
          index
        ].DatumZahlung.split("-")
          .reverse()
          .join(".");
      }
      if (
        helperProjekt.Schlussbericht == null ||
        helperProjekt.Schlussbericht == ""
      ) {
        this.schlussberichtDownload = false;
        this.schlussberichtInfo = true;
      } else {
        this.schlussberichtDownload = true;
        this.schlussberichtInfo = false;
      }
      if (
        helperProjekt.Antragsformular == null ||
        helperProjekt.Antragsformular == ""
      ) {
        this.antragsformularInfo = true;
        this.antragsformularDownload = false;
      } else {
        this.antragsformularDownload = true;
        this.antragsformularInfo = false;
      }

      /* Land und KPerson mit leeren Strings befüllen, falls null (wegen Binding) */
      if (helperProjekt.land == null) {
        helperProjekt.land = {
          Name: "",
          Kontinent: "",
        };
      }
      if (helperProjekt.kontaktperson == null) {
        helperProjekt.kontaktperson = {
          Name: "",
          Vorname: "",
          Email: "",
          Ort: "",
          Organisation: "",
          Land: "",
          PLZ: "",
          Adresse: "",
          Website: "",
        };
      }
      /* Helper Projekt auf bindbares Projekt kopieren */
      this.project = helperProjekt;
    },
  },
};
</script>




<style scoped>
.dropdown {
  position: relative;
  width: 100%;
}
.error-feedback {
  color: red;
  font-weight: 700;
}
.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder {
  opacity: 0.7;
}
.dropdown-selected {
  cursor: pointer;
}
.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 300px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}
.Bezahlt {
  background-color: #66bb6a !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Abgelehnt {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Abgeschrieben {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Erfasst {
  background-color: #bdbdbd !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Bewilligt {
  background-color: #dce775 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Ausstehend {
  background-color: #dce775 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.nicht-bearbeitet-status {
  background-color: #ff7043;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
input {
  color: grey;
}
.popup {
  animation: drop 1s ease forwards;
}
@keyframes drop {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY();
    opacity: 1;
  }
}
</style>

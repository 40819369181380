<template>
  <div>
    <div>
      <div
        v-if="this.mitgliederGeladen"
        class="w-full lg:w-8/12 px-4 mx-auto mt-6"
      >
        <div
          class="
            relative
            flex flex-col
            min-w-0
            break-words
            w-full
            mb-6
            shadow-lg
            rounded-lg
            bg-gray-100
            border-0
            pb-8
          "
        >
          <div relative>
            <div v-if="buttonMitgliedBearbeiten" class="absolute top-0 right-0">
              <button
                @click="onMitgliedBearbeiten()"
                class="
                  bg-green-600
                  hover:bg-green-500
                  text-white text-xs
                  font-bold
                  mt-7
                  mr-14
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                  uppercase
                "
              >
                <span>Mitglied bearbeiten</span>
              </button>
            </div>
          </div>

          <Form @submit="neuesMitgliedSpeichern">
            <div class="rounded-t bg-gray-100 mb- ml-4 0 px-6 py-6">
              <div class="flex justify-between">
                <div
                  id="title-info"
                  class="text-blueGray-700 text-xl font-bold"
                >
                  {{ title }}
                </div>

                <div
                  v-if="textMitgliedInBearbeitung"
                  class="
                    lg:text-lg
                    font-bold
                    text-green-700
                    lg:mr-80
                    mr-1
                    lg:mt-0
                    mt-12
                    text-base
                  "
                >
                  Mitglied kann jetzt bearbeitet werden!
                </div>
              </div>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="relative">
                <div class="relative">
                  <div class="absolute top-0 right-0"></div>
                  <h6
                    class="
                      text-blueGray-400 text-sm
                      mt-3
                      mb-6
                      font-bold
                      uppercase
                    "
                  >
                    Mitglieder Infromationen
                  </h6>
                  <fieldset id="migliedFormInfos">
                    <div class="flex flex-wrap mb-3">
                      <!-- Mitglieder Briefanrede Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label class="block uppercase text-xs font-bold mb-2">
                            Briefanrede
                          </label>
                          <select
                            class="
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              bg-white
                              rounded
                              text-sm
                              shadow-md
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="mitglied.Anrede"
                          >
                            <option></option>
                            <option>Herr + Frau</option>
                            <option>Herr</option>
                            <option>Frau</option>
                          </select>
                        </div>
                      </div>
                      <!-- Mitglieder Adressanrede Input -->
                      <div class="w-full lg:w-4/12 px-4">
                        <div class="relative w-full mb-3">
                          <label class="block uppercase text-xs font-bold mb-2">
                            Adressanrede
                          </label>
                          <select
                            class="
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              bg-white
                              rounded
                              text-sm
                              shadow-md
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="mitglied.Briefanrede"
                          >
                            <option>Sehr geehrte Herr und Frau</option>
                            <option>Sehr geehrter Herr</option>
                            <option>Sehr geehrte Frau</option>
                            <option>Sehr geehrte Damen und Herren</option>
                          </select>
                        </div>
                      </div>
                
                      <div class="w-full lg:w-5/12 px-4">
                      </div>
                      <div class="lg:w-2/12 px-4 flex">
                        <div class="mt-2 ">
                         
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Aktivmitglied?
                            </label>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioAktivmitglied"
                                value= true
                                v-model="mitglied.IsAktivmitglied"
                              />
                              <span class="ml-2">Ja</span>
                            </label>
                          
                          <div>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioAktivmitglied"
                                value= false
                                v-model="mitglied.IsAktivmitglied"
                              />
                              <span class="ml-2">Nein</span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="w-full lg:w-2/12 px-4">
                        <div class="mt-2">
                          <div>
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Passivmitglied?
                            </label>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioPassivmitglied"
                                value= true 
                                v-model="mitglied.IsPassivmitglied"
                              />
                              <span class="ml-2">Ja</span>
                            </label>
                          </div>
                          <div>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioPassivmitglied"
                                value= false 
                                v-model="mitglied.IsPassivmitglied"
                              />
                              <span class="ml-2">Nein</span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="w-full lg:w-2/12 px-4">
                        <div class="mt-2">
                          <div>
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Vorstand?
                            </label>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioVorstand"
                                value= true
                                v-model="mitglied.IsVorstand"
                              />
                              <span class="ml-2">Ja</span>
                            </label>
                          </div>
                          <div>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioVorstand"
                                value= false
                                v-model="mitglied.IsVorstand"
                              />
                              <span class="ml-2">Nein</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="w-full lg:w-2/12 px-4">
                        <div class="mt-2">
                          <div>
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Kassier?
                            </label>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioKassier"
                                value= true
                                v-model="mitglied.IsKassier"
                              />
                              <span class="ml-2">Ja</span>
                            </label>
                          </div>
                          <div>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioKassier"
                                value= false
                                v-model="mitglied.IsKassier"
                               
                              />
                              <span class="ml-2">Nein</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="w-full lg:w-2/12 px-4">
                        <div class="mt-2">
                          <div>
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Präsident?
                            </label>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioPraesi"
                                value= true
                                v-model="mitglied.IsPraesident"
                              />
                              <span class="ml-2">Ja</span>
                            </label>
                          </div>
                          <div>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radioPraesi"
                                value= false
                                v-model="mitglied.IsPraesident"
                              
                              />
                              <span class="ml-2">Nein</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    </fieldset>
                  
                  <hr class="border-b-1 border-gray-400" />
                  <div class="flex justify-between">
                    <div
                      class="
                        text-blueGray-400 text-sm
                        mt-3
                        mb-6
                        font-bold
                        uppercase
                      "
                    >
                      Adressinformationen
                    </div>
                  </div>
                  <div>
                    <fieldset id="mitgliedFormAdress">
                      <div class="flex flex-wrap">
                        <!-- PLZ Input -->
                        <div class="w-full lg:w-3/12 px-4">
                          <div class="relative w-full mb-3">
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              PLZ
                            </label>
                            <Field
                              type="text"
                              name="plz"
                              id="plz"
                              class="
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-blueGray-600
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                              v-model="mitglied.PLZ"
                            />
                            <ErrorMessage name="plz" class="error-feedback" />
                          </div>
                        </div>
                        <!-- Ort Input -->
                        <div class="w-full lg:w-3/12 px-4">
                          <div class="relative w-full mb-3">
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Ort
                            </label>
                            <Field
                              type="text"
                              name="ort"
                              id="ort"
                              class="
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-blueGray-600
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                              v-model="mitglied.Ort"
                            />
                            <ErrorMessage name="ort" class="error-feedback" />
                          </div>
                        </div>
                        <!-- Adresse Input -->
                        <div class="w-full lg:w-3/12 px-4">
                          <div class="relative w-full mb-3">
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Adresse
                            </label>
                            <Field
                              type="text"
                              name="adresse"
                              id="adresse"
                              class="
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-blueGray-600
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                              v-model="mitglied.Adresse"
                            />
                            <ErrorMessage
                              name="adresse"
                              class="error-feedback"
                            />
                          </div>
                        </div>
                        <!-- Adresszusatz Input -->
                        <div class="w-full lg:w-3/12 px-4">
                          <div class="relative w-full mb-3">
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Adresszusatz (Postfach)
                            </label>
                            <Field
                              type="text"
                              name="adresszusatz"
                              id="adresszusatz"
                              class="
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-blueGray-600
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                              v-model="mitglied.Adresszusatz"
                            />
                            <ErrorMessage
                              name="adresszusatz"
                              class="error-feedback"
                            />
                          </div>
                        </div>
                      </div>

                      <hr class="mt-6 border-b-1 border-gray-400" />

                      <form id="personenForm">
                        <fieldset id="mitgliedFormPersonen">
                          <div class="relative">
                            <div
                              v-for="(person, index) in this.mitglied.Personen"
                              :key="index"
                            >
                              <h6
                                class="
                                  text-blueGray-400 text-sm
                                  mt-3
                                  mb-6
                                  font-bold
                                  uppercase
                                "
                              >
                                Person {{ index + 1 }}
                              </h6>
                              <div
                                v-if="buttonAddPerson"
                                class="absolute top-0 right-0"
                              >
                                <button
                                  type="button"
                                  @click="onPersonHinzufuegen()"
                                  class="
                                    mt-4
                                    mr-4
                                    mb-3
                                    bg-green-600
                                    hover:bg-green-500
                                    text-white text-xs
                                    font-bold
                                    py-2
                                    px-4
                                    rounded
                                    inline-flex
                                    items-center
                                    uppercase
                                  "
                                >
                                  <span>Weitere Person hinzufügen</span>
                                </button>
                              </div>

                              <div class="absolute top-0 right-0"></div>

                              <div class="flex flex-wrap mt-3">
                                <div class="w-full lg:w-3/12 px-4">
                                  <div class="relative w-full mb-3">
                                    <label
                                      class="
                                        block
                                        uppercase
                                        text-blueGray-600 text-xs
                                        font-bold
                                        mb-2
                                      "
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      class="
                                        textfeld
                                        border-0
                                        px-3
                                        py-3
                                        placeholder-blueGray-300
                                        text-blueGray-600
                                        bg-white
                                        rounded
                                        text-sm
                                        shadow
                                        focus:outline-none focus:ring
                                        w-full
                                        ease-linear
                                        transition-all
                                        duration-150
                                      "
                                      v-model="person.Name"
                                    />
                                  </div>
                                </div>

                                <div class="w-full lg:w-3/12 px-4">
                                  <div class="relative w-full mb-3">
                                    <label
                                      class="
                                        block
                                        uppercase
                                        text-blueGray-600 text-xs
                                        font-bold
                                        mb-2
                                      "
                                    >
                                      Vorname
                                    </label>
                                    <input
                                      type="text"
                                      class="
                                        textfeld
                                        border-0
                                        px-3
                                        py-3
                                        placeholder-blueGray-300
                                        text-blueGray-600
                                        bg-white
                                        rounded
                                        text-sm
                                        shadow
                                        focus:outline-none focus:ring
                                        w-full
                                        ease-linear
                                        transition-all
                                        duration-150
                                      "
                                      v-model="person.Vorname"
                                    />
                                  </div>
                                </div>
                                <!-- Geschlecht Input -->
                                <div class="w-full lg:w-3/12 px-4">
                                  <div class="relative w-full mb-3">
                                    <label
                                      class="
                                        block
                                        uppercase
                                        text-xs
                                        font-bold
                                        mb-2
                                      "
                                    >
                                      Geschlecht
                                    </label>
                                    <select
                                      class="
                                        border-0
                                        px-3
                                        py-3
                                        placeholder-blueGray-300
                                        bg-white
                                        rounded
                                        text-sm
                                        shadow-md
                                        focus:outline-none focus:ring
                                        w-full
                                        ease-linear
                                        transition-all
                                        duration-150
                                      "
                                      v-model="person.Geschlecht"
                                    >
                                      <option>Weiblich</option>
                                      <option>Männlich</option>
                                      <option>Divers</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </fieldset>
                      </form>

                      <div v-if="showOrganisation">

                      <hr class="mt-6 border-b-1 border-gray-400" />

                      <h6
                        class="
                          text-blueGray-400 text-sm
                          mt-3
                          mb-6
                          font-bold
                          uppercase
                        "
                      >
                        Organisations Informationen
                      </h6>
                      <div
                                v-if="buttonAddOrganisation"
                                class="absolute bottom-0 mb-6 right-0"
                              >
                                <button
                                  type="button"
                                  @click="onOrganisationHinzufuegen()"
                                  class="
                                    mt-4
                                    mr-4
                                    mb-3
                                    bg-green-600
                                    hover:bg-green-500
                                    text-white text-xs
                                    font-bold
                                    py-2
                                    px-4
                                    rounded
                                    inline-flex
                                    items-center
                                    uppercase
                                  "
                                >
                                  <span>Organisation hinzufügen</span>
                                </button>
                              </div>
                      <div
                        v-if="showInputFields"
                        class="flex flex-wrap mb-3"
                      >
                        <div class="w-full lg:w-4/12 px-4">
                          <div class="relative w-full mb-3">
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Organisationsname
                            </label>
                            <Field
                              type="text"
                              name="organisationsName"
                              id="organisationsName"
                              class="
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-blueGray-600
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                              v-model="mitglied.Organisation.Name"
                            />
                            <ErrorMessage
                              name="organisationsName"
                              class="error-feedback"
                            />
                          </div>
                        </div>
                        <div class="w-full lg:w-3/12 px-4">
                          <div class="relative w-full mb-3">
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Organisationsort
                            </label>
                            <Field
                              type="text"
                              name="organisationsOrt"
                              id="organisationsOrt"
                              class="
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-blueGray-600
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                              v-model="mitglied.Organisation.Adresse"
                            />
                            <ErrorMessage
                              name="organisationsOrt"
                              class="error-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      <div v-else class="mb-3">
                        Das Mitglied ist momentan keine Organisation
                      </div>
                    </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="buttonAbbrechenBearbeiten"
              class="absolute bottom-0 left-0 lg:p-0 p-2"
            >
              <button
                @click="onAbbrechen()"
                class="
                  mb-8
                  ml-14
                  bg-green-600
                  hover:bg-green-500
                  text-white text-xs
                  font-bold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                  uppercase
                "
              >
                <span>{{ abbrechen }}</span>
              </button>
            </div>
            <div
              v-if="buttonAbbrechenCreate"
              class="absolute bottom-0 left-0 lg:p-0 p-2"
            >
              <button
                @click="onAbbrechen()"
                class="
                  mb-8
                  ml-14
                  bg-red-600
                  hover:bg-red-500
                  text-white text-xs
                  font-bold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                  uppercase
                "
              >
                <span>{{ abbrechen }}</span>
              </button>
            </div>
            <div
              v-if="buttonAenderungVerwerfen"
              class="absolute bottom-0 left-0 lg:p-0 p-2"
            >
              <button
                @click="reloadPage()"
                type="button"
                class="
                  mb-8
                  ml-14
                  bg-red-600
                  hover:bg-red-500
                  text-white text-xs
                  font-bold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                  uppercase
                "
              >
                <span>Änderungen verwerfen</span>
              </button>
            </div>
            <!-- Div für Aenderungen speichern -->
            <div v-if="buttonAenderungenSpeichern" @click="onAenderungenSpeichern()" class="absolute bottom-0 right-0">
              <div
                class="
                  cursor-pointer
                  mb-1
                  lg:mb-8
                  mr-14
                  bg-green-600
                  hover:bg-green-500
                  text-white text-xs
                  font-bold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                  uppercase
                "
                
              >
                <span> {{ speichern }} </span>
              </div>
            </div>
            
            <div v-if="buttonSpeichern" class="absolute bottom-0 right-0">
              <button
                class="
                  mb-1
                  lg:mb-8
                  mr-14
                  bg-green-600
                  hover:bg-green-500
                  text-white text-xs
                  font-bold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                  uppercase
                "
                type="submit"
      
              >
                <span>{{ speichern }}</span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <!-- Pop UP Mitgliederänderungen werden gespeichert-->
    <div
      v-if="showPopupSpeichern"
      class="
        bg-black
        fixed
        inset-0
        flex
        items-center
        justify-center
        bg-opacity-70
        text-black
      "
    >
      <div class="bg-white p-12 rounded-md relative">
        <div v-if="!showButtons">
          {{ aenderungsPopUpText }}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-10 w-10 ml-28 animate-spin mt-5 text-green-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </div>
        <div v-if="mitgliedServerResponse" role="alert">
          <div
            class="
              bg-red-500
              text-white
              font-bold
              rounded-t
              px-4
              py-2
              text-center
            "
          >
            Leider ist ein Fehler aufgetreten.
          </div>
          <div
            class="
              border border-t-0 border-red-400
              rounded-b
              bg-red-100
              px-4
              py-3
              text-red-700
            "
          >
            <p class="text-center">Folgender Fehler ist aufgetreten:</p>
            <p class="text-center font-bold">{{ mitgliedServerResponse }}</p>
            <p>
              Versuchen Sie es später erneut, oder melden Sie den Fehler dem
              Administrator.
            </p>
            <p>
              Bitte warten Sie ca. 30 Sekunden, bis Sie zur Mitgliederübersicht
              zurückkehren.
            </p>
          </div>
          <button
            @click="onAbbrechen()"
            class="
              bg-green-600
              hover:bg-green-500
              mr-20
              mb-5
              text-white
              font-bold
              uppercase
              w-full
              mt-5
              text-xs
              px-6
              py-3
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
          >
            Zurück zur Mitgliederübersicht
          </button>
        </div>
        <div v-if="showButtons">
          Mitglied wurde erfolgreich abgespeichert!
          <div class="mt-2">
            <button
              id="buttonVerwerfen"
              @click="reloadPage()"
              class="
                mr-24
                mb-0
                bg-green-700
                hover:bg-green-600
                text-white
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Weiteres Mitglied anlegen
            </button>

            <button
              @click="onAbbrechen()"
              class="
                mb-0
                bg-green-700
                hover:bg-green-600
                text-white
                font-bold
                uppercase
                text-xs
                px-12
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Zurück zur Mitgliederübersicht
            </button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
import dataService from "../services/dataService";
/* Forms und Validation libs importieren */
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },

  props: {
    title: String,
    speichern: String,
    abbrechen: String,
    bearbeiten: Boolean,
  },

  data() {
    return {
      mitgliederGeladen: false,

      bearbeitungsModus: true,
      buttonAddPerson: true,
      buttonMitgliedBearbeiten: true,
      buttonSpeichern: true,
      buttonAbbrechenCreate: true,
      buttonAbbrechenBearbeiten: true,
      textMitgliedInBearbeitung: true,
      buttonAenderungVerwerfen: true,
      buttonAenderungenSpeichern: false,
      buttonAddOrganisation: false,
      showInputFields: false,
      showOrganisation: false,

      //PopUp Trigger => Anzeigen des Popups, dass Mitglied gespeichert wird
      showPopupSpeichern: false,
      //Text, der angezeigt wird, wenn Mitglied gespeichert wrird
      aenderungsPopUpText: "",
      //Popup nach dem speichern anzeigen, mit den Buttons:
        //Zur Tabelle zurückkehren oder 
        //Neues Mitglied erstellen
      showButtons: false,

      mitgliedServerResponse: null,

      mitglied: {
        Adresse: "",
        Adresszusatz: "",
        PLZ: "",
        Ort: "",
        IsAktivmitglied: false,
        IsPassivmitglied: false,
        IsPraesident: false,
        IsKassier: false,
        IsVorstand: false,
        Anrede: "",
        Briefanrede: "",
        Organisation: {
          Name: "",
          Adresse: "",
        },
        Personen: [
          {
            Name: "",
            Vorname: "",
            Geschlecht: "Weiblich",
            id: 1,
          },
        ],
      },
    };
  },

  created() {
    //Schauen ob man im Form bearbeiten oder Create ist Property "bearbeiten" in eine Variable abspeichern
    this.bearbeitungsModus = this.bearbeiten;
  },

  async mounted() {
    //Falls im Bearbeitungsmodus, wird id aus route geladen und DataService aufgerufen, um gewünschtes Mitglied zu holen
    if (this.bearbeitungsModus) {
      var id = this.$route.params.id;
      await this.getMitglied(id);
    } else {
      this.mitgliederGeladen = true;
    }

    this.bearbeitenORcreate();
  },

  methods: {

    async getMitglied(id) {
      const response = await dataService.getMitgliedDetail(id);
      this.mitglied = response.data;
      //Ein paar Organisationen haben keine Namen und Vornamen, in Mitglied bearbeiten aber default mässig immer Person anzeigen
      if(this.mitglied.Personen[0]==null){
        var person = {
        Vorname: "",
        Nachname: "",
        Geschlecht: "",
        id:1
      };
      this.mitglied.Personen.push(person);
      }
      //Schauen ob Organisation leer ist oder nicht
      if(this.mitglied.Organisation==null||this.mitglied.Organisation.Name==""){
        //Input field von Organisation wird nicht angezeigt wenn Organisationsname leer ist
        this.showInputFields=false;
        this.showOrganisation=false;
  
      }else{
        this.showInputFields=true;
        this.showOrganisation=true;
      }
      this.mitgliederGeladen = true;
    },

    reloadPage() {
      //Wider weg machen und neu laden
      window.location.reload();
    },

    onPersonHinzufuegen() {
      var person = {
        Vorname: "",
        Nachname: "",
        Geschlecht: "Weiblich",
        id: this.mitglied.Personen.length + 1,
      };
      this.mitglied.Personen.push(person);
    },

    onMitgliedBearbeiten() {
       //Text Schwarz verfärben!
      var input = document.getElementsByClassName("textfeld");
      for (var i = 0; i < input.length; i++) {
        input[i].style.color = "black";
      }
      document.getElementById("mitgliedFormPersonen").disabled = false;
      document.getElementById("mitgliedFormAdress").disabled = false;
      document.getElementById("migliedFormInfos").disabled = false;
      this.showOrganisation=true;
      this.buttonAbbrechenBearbeiten = false;
      this.textMitgliedInBearbeitung = true;
      this.buttonSpeichern = false;
      this.buttonAenderungenSpeichern = true;
      this.buttonAenderungVerwerfen = true;
      this.buttonAddPerson = true;
      if(!this.mitglied.Organisation||this.mitglied.Organisation.Name==""){
      this.buttonAddOrganisation = true;  
      }else{
        this.buttonAddOrganisation = false;
      }
      this.buttonMitgliedBearbeiten = false;
    },

    onAbbrechen() {
      this.$router.push({ name: "Mitglieder" });
    },

    onOrganisationHinzufuegen(){
     
      if(this.mitglied.Organisation){
      this.showInputFields=true;
      }else{
        var organisation = {
        Name: "",
        Adresse: ""
      };
      this.mitglied.Organisation=organisation
      this.showInputFields=true;
      }
      this.buttonAddOrganisation=false;
    },

    bearbeitenORcreate() {
      //Verschiedene Buttons sichtbar machen oder nicht
      if (this.bearbeitungsModus) {
        //Form Bearbeiten
        document.getElementById("mitgliedFormPersonen").disabled = true;
        document.getElementById("mitgliedFormAdress").disabled = true;
        document.getElementById("migliedFormInfos").disabled = true;
        this.buttonAddPerson = false;
        this.buttonMitgliedBearbeiten = true;
        this.buttonSpeichern = false;
        this.buttonAenderungenSpeichern = false;
        this.buttonAbbrechenCreate = false;
        this.buttonAbbrechenBearbeiten = true;
        this.textMitgliedInBearbeitung = false;
        this.buttonAenderungVerwerfen = false;
      } else {
        //Form Create
        this.buttonAddPerson = true;
        this.buttonMitgliedBearbeiten = false;
        this.buttonSpeichern = true;
        this.showInputFields = true;
        this.showOrganisation = true;
        this.buttonAbbrechenCreate = true;
        this.buttonAbbrechenBearbeiten = false;
        this.buttonAenderungenSpeichern = false;
        this.textMitgliedInBearbeitung = false;
        this.buttonAenderungVerwerfen = false;
      }
    },

    async neuesMitgliedSpeichern() {
      this.showPopupSpeichern = true;
      this.aenderungsPopUpText = "Neues Mitglied wird gespeichert ...";
      //Strings in Boolean umwandeln
      if(this.mitglied.IsAktivmitglied == "true"||this.mitglied.IsAktivmitglied == "false"){
        this.mitglied.IsAktivmitglied = this.mitglied.IsAktivmitglied == "true" ? true : false;
      }
      if(this.mitglied.IsPassivmitglied == "true"||this.mitglied.IsPassivmitglied == "false"){
        this.mitglied.IsPassivmitglied = this.mitglied.IsPassivmitglied == "true" ? true : false;
      }
      if(this.mitglied.IsPraesident == "true"||this.mitglied.IsPraesident == "false"){
        this.mitglied.IsPraesident = this.mitglied.IsPraesident == "true" ? true : false;
      }
      if(this.mitglied.IsKassier == "true"||this.mitglied.IsKassier == "false"){
        this.mitglied.IsKassier = this.mitglied.IsKassier == "true" ? true : false;
      }
      if(this.mitglied.IsVorstand == "true"||this.mitglied.IsVorstand == "false"){
        this.mitglied.IsVorstand = this.mitglied.IsVorstand == "true" ? true : false;
      }
      try {
        console.log(await dataService.createMitglied(this.mitglied))
        this.showButtons = true;
        
      } catch (error) {
        this.mitgliedServerResponse = error.message;
      }
    },

    async onAenderungenSpeichern(){
      this.showPopupSpeichern = true;
      this.aenderungsPopUpText = "Änderungen werden gespeichert ...";
    
      try {
        console.log(await dataService.updateMitglied(this.mitglied));
        this.showButtons = false;
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        this.mitgliedServerResponse = error.message;
      }
    },
  },
};
</script>


<style>
input {
  color: grey;
}
.textfeld{
  color: grey;
}
</style>



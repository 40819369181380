import {createRouter, createWebHistory} from 'vue-router'
import Login from '../views/Login'
import Hilfsprojekte from '../views/Hilfsprojekte'
import Performance from '../views/Performance'
import HilfsprojekteDetail from '../views/HilfsprojekteDetail'
import HilfsprojekteCreateProject from '../views/HilfsprojekteCreateProject'
import Mitglieder from '../views/Mitglieder'
import MitgliederDetail from '../views/MitgliederDetail'
import MitgliederCreateMitglied from '../views/MitgliederCreateMitglied'
import GVEinladungen from '../views/GVEinladungen'
import authService from '../services/auth.service'



//Routes für Subpage definieren 
const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Hilfprojekte Verwaltung',
        }
    },
    {
        path: '/performance',
        name: 'Performance',
        component: Performance,
        meta: {
            title: 'Hilfsprojekte Verwaltung',
        }
    },
    {
        path: '/hilfsprojekte',
        name: 'Hilfsprojekte',
        component: Hilfsprojekte,
        meta: {
            title: 'Hilfprojekte Verwaltung',
        }
    },
    {
        path: '/hilfsprojekte/detail/:id',
        name: 'detail',
        component: HilfsprojekteDetail,
        meta: {
            title: 'Hilfprojekte Verwaltung',
        }
    },
    {
        path: '/hilfsprojekte/createProjekt',
        name: 'createProjekt',
        component: HilfsprojekteCreateProject,
        meta: {
            title: 'Hilfprojekte Verwaltung',
        }
    },
    {
       
        path: '/mitglieder/detail/:id',
        name: 'mitgliederDetail',
        component: MitgliederDetail,
        meta: {
            title: 'Hilfprojekte Verwaltung',
        }
    },
    {
        path: '/mitglieder',
        name: 'Mitglieder',
        component: Mitglieder,
        meta: {
            title: 'Hilfprojekte Verwaltung',
        }
    },
    {
        path: '/mitglieder/createMitglied',
        name: 'createMitglied',
        component: MitgliederCreateMitglied,
        meta: {
            title: 'Hilfprojekte Verwaltung',
        }
    },
    {
        path: '/gv-einladungen',
        name: 'GVEinladungen',
        component: GVEinladungen,
        meta: {
            title: 'Hilfprojekte Verwaltung',
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    const publicPage = ['/'];
    const authRequired = !publicPage.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        next('/');
    } else if(authRequired && loggedIn) {
        //Check ob access Token noch valid
            const resp = await authService.checkToken()
            if(resp.status == 200) {
                next()
            } else {
                next('/');
            }
    }
    else {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if(nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else {
        document.title = "Hilfprojekte Verwaltung";
    }
   
      next();
    }
  });

export default router 
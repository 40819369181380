<template>



<CreateProject/>


    

</template>


<script>
import CreateProject from '../components/CreateProject'


export default {
 name: 'HilfsprojekteCreateProject',
     components: {
         CreateProject
     }
}
     
</script>



<style scoped>

</style>
<template>
  <div>
    <div>
      <!--Zeilen anzeigen -->
      <div class="sm:-mx-1 md:-mx-8">
        <div
          id="stickyHeaderButtons"
          class="
            justify-items-stretch
            pt-2
            place-items-baseline
            grid grid-cols-12
            gap-2
            z-20
            sticky
            top-0
            bg-white
          "
        >
          <div
            v-if="showButtons"
            class="
              mt-5
              ml-8
              col-start-1 col-span-12
              row-start-2
              md:col-start-1 md:col-span-2 md:row-start-1
              lg:col-start-1 lg:col-span-3
              md:row-start-1
            "
          >
            <p>
              <b>{{ this.filteredProjects.length }}</b> Projekte gefunden
            </p>
            <input
              @click="orderedAndFilteredProjects()"
              id="archiviertCheckbox"
              type="checkbox"
            />
            <label class="text-xs md:text-sm" for="archiviertCheckbox">
              Archivierte Projekte anzeigen
            </label>
          </div>

          <div
            v-if="showButtons"
            class="
              place-self-center
              hidden
              lg:table-cell
              row-start-1
              md:mr-5 md:col-start-1 md:col-span-7
              xl:col-start-3 xl:col-span-2
            "
          >
            <button
              @click="loadBackup()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                rounded
                inline-flex
                items-center
              "
            >
              <span class="text-xs md:text-base">Backup</span>
            </button>
          </div>
          <!-- Searchbar -->
          <div
            v-if="showButtons"
            class="
              col-start-1 col-span-11
              row-start-1
              md:col-start-2 md:col-span-5
              lg:col-start-5 lg:col-span-3
              md:row-start-1
            "
          >
            <div class="bg-gray-100 flex items-center rounded-lg shadow-md">
              <input
                class="
                  rounded-l-full
                  w-full
                  py-2
                  px-4
                  md:py-4 md:px-10
                  bg-gray-100
                  text-gray-700
                  md:text-lg
                  text-xs
                  leading-tight
                  focus:outline-none
                "
                id="search"
                type="text"
                v-model="searchString"
                placeholder="Suchen ..."
              />

              <div class="p-3 md:p-2.5">
                <button
                  class="
                    bg-green-600
                    text-white
                    rounded-full
                    p-2
                    hover:bg-green-500
                    focus:outline-none
                    w-7
                    h-7
                    md:w-10 md:h-10
                    flex
                    items-center
                    justify-center
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <!-- Searchbar Ende -->
          <!-- Neues Projekt-->
          <div
            v-if="showButtons"
            class="
              place-self-start
              md:place-self-center
              col-start-9 col-span-1
              row-start-2
              mt-5
              md:mt-0 md:col-start-7 md:col-span-4
              lg:col-start-8 lg:col-span-2
              md:row-start-1
              flex flex-col
              inline-flex
              items-center
            "
          >
            <button
              @click="onCreateProjekt()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                rounded
                inline-flex
                items-center
              "
            >
              <span class="text-xs md:text-base">Projekt erstellen</span>
            </button>
          </div>

          <div
            v-if="showButtons"
            class="
              place-self-center
              hidden
              lg:table-cell
              md:col-end-13
              row-start-2
              lg:col-start-10 lg:col-span-3
              md:row-start-1
              flex flex-col
              inline-flex
              items-center
            "
          >
            <button
              @click="generateCsvFromFilteredData()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                rounded
                inline-flex
                items-center
              "
            >
              <span class="text-xs md:text-base">Daten exportieren</span>
            </button>
          </div>
          <!-- Neues Projekt Ende -->

          <!-- Thead sticky -->
          <div
            v-if="projects.length"
            class="col-start-1 row-start-3 md:row-start-2 col-span-12"
          >
            <div class="align-middle inline-block min-w-full sm:px-6 md:px-8">
              <div class="shadow-lg overflow-auto border-gray-500 rounded-t-lg">
                <table
                  class="
                    max-w-full
                    divide-y divide-gray-200
                    table-fixed
                    w-full
                    sm:overflow-x-scroll
                  "
                >
                  <thead class="bg-gray-200 max-h-10">
                    <tr>
                      <th
                        class="
                          px-1
                          py-2
                          md:px-6 md:py-3 md:w-1/12
                          w-3/12
                          text-center
                          md:w-1/12
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                        "
                      >
                        #Projekt
                      </th>
                      <th
                        scope="col"
                        class="
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                          px-1
                          py-4
                          md:px-6 md:py-3 md:w-3/12
                          w-4/12
                        "
                      >
                        Projektname
                      </th>
                      <th
                        scope="col"
                        class="
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                          px-1
                          py-2
                          md:px-6 md:py-3 md:w-1/12
                          w-3/12
                        "
                      >
                        Status
                      </th>

                      <th
                        scope="col"
                        class="
                          px-6
                          py-3
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          md:w-2/12
                          tracking-wider
                          hidden
                          md:table-cell
                        "
                      >
                        Organisation
                      </th>
                      <th
                        scope="col"
                        class="
                          px-6
                          py-3
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          md:w-2/12
                          tracking-wider
                          hidden
                          md:table-cell
                        "
                      >
                        Land
                      </th>
                      <th
                        scope="col"
                        class="
                          px-4
                          py-3
                          md:w-2/12
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                          hidden
                          md:table-cell
                        "
                      >
                        Verantwortliche/r
                        <!-- Land Filter -->
                      </th>
                      <th
                        scope="col"
                        class="
                          px-1
                          py-3
                          md:w-1/12
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                          hidden
                          md:table-cell
                        "
                      >
                        Betrag
                      </th>
                      <th
                        scope="col"
                        class="
                          relative
                          md:w-1/12
                          px-1
                          py-2
                          md:px-3 md:py-3
                          w-2/12
                        "
                      >
                        <span></span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          id="projectTableBody"
          class="sm:h-screen md:h-auto"
          v-if="filteredProjects.length || searchString == ''"
        >
          <div class="align-middle inline-block min-w-full sm:px-6 md:px-8">
            <div class="shadow-lg overflow-auto border-gray-500 rounded-b-lg">
              <table
                class="max-w-screen divide-y divide-gray-200 table-fixed w-full"
              >
                <tbody
                  class="
                    project
                    bg-white
                    divide-y divide-gray-200
                    text-gray-800 text-sm
                    break-words
                  "
                >
                  <tr v-for="project in filteredProjects" :key="project.id">
                    <td class="px-1 py-2 md:px-2 md:py-4 md:w-1/12 w-3/12">
                      <div class="text-center">{{ project.Projektnummer }}</div>
                    </td>

                    <td class="px-1 py-4 md:px-6 md:py-4 md:w-3/12 w-4/12">
                      <div>{{ project.Name }}</div>
                    </td>

                    <td class="px-1 py-2 md:px-6 md:py-4 md:w-1/12 w-3/12">
                      <span
                        v-bind:class="project.Status"
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          nicht-bearbeitet-status
                        "
                      >
                        {{ project.Status }}
                      </span>
                    </td>
                    <td
                      class="
                        px-1
                        py-2
                        md:px-6 md:py-4 md:w-2/12
                        hidden
                        md:table-cell
                      "
                    >
                      {{ project.kontaktperson.Organisation }}
                    </td>
                    <td
                      class="
                        px-1
                        py-2
                        md:px-6 md:py-4 md:w-2/12
                        hidden
                        md:table-cell
                      "
                    >
                      {{ project.land.Name }}
                    </td>
                    <td
                      class="
                        px-1
                        py-2
                        md:px-4 md:py-4 md:w-2/12
                        hidden
                        md:table-cell
                      "
                    >
                      <div class="flex flex-row items-center">
                        <span
                          >{{ project.VerantwortlicherName }}
                          {{ project.VerantwortlicherVorname }}</span
                        >
                      </div>
                    </td>
                    <td
                      class="px-1 py-2 md:py-4 md:w-1/12 hidden md:table-cell"
                    >
                      {{ project.Betrag }} {{ project.Waehrung }}
                    </td>

                    <td
                      class="
                        px-1
                        py-2
                        md:py-2.5
                        whitespace-nowrap
                        text-right text-sm
                        font-medium
                        md:w-1/12
                        w-2/12
                      "
                    >
                      <a
                        @click="onDetails(project.id)"
                        class="
                          cursor-pointer
                          mr-3
                          text-indigo-600
                          hover:text-indigo-900
                        "
                        >Details</a
                      >
                      <button
                        class="w-6 h-8 ml-2 hidden md:table-cell"
                        @click="triggerPopupLoeschen(), sendProjekt(project)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          v-if="sucheFehlgeschlagen && !projekteNichtGeladen"
          class="grid place-items-center h-full mt-20"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-40 w-40"
              viewBox="0 0 20 20"
              fill="#FBBF24"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>
            <span class="text-2xl font-medium">
              Leider wurden keine Projekte gefunden für: "{{ searchString }}"
            </span>
          </div>
        </div>
      </div>
      <div v-if="projekteLaden" class="grid place-items-center h-full mt-20">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="
              margin: auto;
              background: none;
              display: block;
              shape-rendering: auto;
            "
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <path
              fill="none"
              stroke="#57a25e"
              stroke-width="8"
              stroke-dasharray="42.76482137044271 42.76482137044271"
              d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
              stroke-linecap="round"
              style="transform: scale(0.8); transform-origin: 50px 50px"
            >
              <animate
                attributeName="stroke-dashoffset"
                repeatCount="indefinite"
                dur="1.5384615384615383s"
                keyTimes="0;1"
                values="0;256.58892822265625"
              ></animate>
            </path>
          </svg>
        </div>
        <div class="content-center">
          <span class="text-2xl font-medium"> Laden der Projekte ... </span>
        </div>
      </div>
      <div
        v-if="projekteNichtGeladen"
        class="grid place-items-center h-full mt-20"
      >
        <div>
          <svg
            width="160px"
            height="160px"
            viewBox="0 0 24 24"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
          >
            <g transform="translate(0 -1028.4)">
              <path
                d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                transform="translate(0 1029.4)"
                fill="#c0392b"
              />
              <path
                d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                transform="translate(0 1028.4)"
                fill="#e74c3c"
              />
              <path
                d="m7.0503 1037.8 3.5357 3.6-3.5357 3.5 1.4142 1.4 3.5355-3.5 3.536 3.5 1.414-1.4-3.536-3.5 3.536-3.6-1.414-1.4-3.536 3.5-3.5355-3.5-1.4142 1.4z"
                fill="#c0392b"
              />
              <path
                d="m7.0503 1036.8 3.5357 3.6-3.5357 3.5 1.4142 1.4 3.5355-3.5 3.536 3.5 1.414-1.4-3.536-3.5 3.536-3.6-1.414-1.4-3.536 3.5-3.5355-3.5-1.4142 1.4z"
                fill="#ecf0f1"
              />
            </g>
          </svg>
        </div>
        <div class="content-center">
          <span class="text-2xl font-medium">
            Es wurden keine Projekte in der Datenbank gefunden! Sie müssen ein
            Backup durchführen!
          </span>
        </div>
        <div class="content-center mt-6">
          <button
            @click="loadBackup(), changeBoolean()"
            class="
              bg-green-500
              hover:bg-green-600
              text-white
              font-bold
              py-4
              px-8
              rounded
              inline-flex
              items-center
            "
          >
            <span class="md:text-xl">Backup durchführen</span>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="showBackupPopup"
      class="
        bg-black
        fixed
        inset-0
        z-30
        flex
        items-center
        justify-center
        bg-opacity-70
        text-black
      "
    >
      <div class="bg-white p-12 rounded-md relative">
        <div v-if="showbackupNormaleAnsicht">
          <div v-if="oberesDivInBackupPopUP">
            <div class="absolute top-0 right-0">
              <button
                @click="triggerPopup()"
                class="
                  inline-flex
                  items-center
                  justify-center
                  w-10
                  h-10
                  mr-3
                  mt-3
                  text-white
                  transition-colors
                  duration-150
                  bg-red-400
                  rounded-lg
                  focus:shadow-outline
                  hover:bg-red-300
                "
              >
                X
              </button>
            </div>
            <p class="text-xl mb-5">Backup erstellen</p>
            <button
              @click="BackupDownload()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                mb-5
                rounded
                inline-flex
                items-center
              "
            >
              <span class="text-xs md:text-base">Backup herunterladen</span>
            </button>
            <hr class="bg-gray-400 border-2 border-gray-400" />
          </div>
          <p class="text-xl mt-5">Daten aus Backup wiederherstellen</p>
          <p class="mt-3 mb-1">Bitte wählen Sie Ihre Backup Datei:</p>
          <form>
            <input
              id="backupFile"
              type="file"
              accept=".json"
              class="
                border-0
                px-3
                py-2.5
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </form>
          <div class="mt-2 text-red-600" v-if="textKeineDateiAusgewaehlt">
            Keine Datei ausgewählt!
          </div>
          <div>
            <p v-if="oberesDivInBackupPopUP" class="mt-5 text-red-600">
              ACHTUNG: Mit dem Laden der Backup Datei,<br />
              löschen/überschreiben Sie alle vorhandenen Daten!
            </p>
            <button
              @click="BackupImportieren()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                rounded
                inline-flex
                items-center
                mt-3
              "
            >
              <span class="text-xs md:text-base">Backup importieren</span>
            </button>
          </div>
        </div>
        <div v-if="showBackupLoadingIcon">
          {{ this.textBackupPopUp }}
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-10 w-10 animate-spin mt-5 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </div>
        </div>
        <div class="form-group mt-3">
          <div v-if="projektServerResponse" role="alert">
            <div
              class="
                bg-red-500
                text-white
                font-bold
                rounded-t
                px-4
                py-2
                text-center
              "
            >
              Leider ist ein Fehler aufgetreten.
            </div>
            <div
              class="
                border border-t-0 border-red-400
                rounded-b
                bg-red-100
                px-4
                py-3
                text-red-700
              "
            >
              <p class="text-center">Folgender Fehler ist aufgetreten:</p>
              <p class="text-center font-bold">{{ projektServerResponse }}</p>
              <p>
                Versuchen Sie es später erneut, oder melden Sie den Fehler dem
                Administrator.
              </p>
              <p>
                Bitte warten Sie ca. 30 Sekunden, bis Sie die Seite neu Laden
                und zum Projekt zurückkehren
              </p>
            </div>
            <button
              @click="reloadPage"
              class="
                bg-green-600
                hover:bg-green-500
                mr-20
                mb-5
                text-white
                font-bold
                uppercase
                w-full
                mt-5
                text-xs
                px-6
                py-3
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              "
            >
              Zurück zum Projekt
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showPopupLoeschen"
      class="
        popup
        bg-black
        fixed
        inset-0
        flex
        items-center
        justify-center
        z-30
        bg-opacity-70
        text-black
      "
    >
      <div class="bg-white px-24 py-12 rounded-md relative">
        Möchtest du das Projekt <br /><strong>
          {{ aktuellesProjekt.Name }}
        </strong>
        <br />
        für immer löschen?

        <div v-if="showConfirmMessage" class="mt-4">
          <div class="absolute bottom-0 left-0 mt-10">
            <button
              id="buttonVerwerfen"
              @click="triggerPopupLoeschen()"
              class="
                mb-5
                ml-12
                bg-red-700
                hover:bg-red-600
                text-white
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Abbrechen
            </button>
          </div>

          <div class="absolute bottom-0 right-0">
            <button
              id="buttonLoeschen"
              @click="deleteProjekt()"
              class="
                mb-5
                mr-12
                mt-5
                bg-green-700
                hover:bg-green-600
                text-white
                font-bold
                uppercase
                text-xs
                px-12
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Ja
            </button>
          </div>
        </div>

        <div v-if="!projektServerResponse && !showConfirmMessage">
          Das Projekt wird gelöscht ...

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-10 w-10 ml-28 animate-spin mt-5 text-green-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </div>
        <div v-if="projektServerResponse" role="alert">
          <div
            class="
              bg-red-500
              text-white
              font-bold
              rounded-t
              px-4
              py-2
              text-center
            "
          >
            Leider ist ein Fehler aufgetreten.
          </div>
          <div
            class="
              border border-t-0 border-red-400
              rounded-b
              bg-red-100
              px-4
              py-3
              text-red-700
            "
          >
            <p class="text-center">Folgender Fehler ist aufgetreten:</p>
            <p class="text-center font-bold">{{ projektServerResponse }}</p>
            <p>
              Versuchen Sie es später erneut, oder melden Sie den Fehler dem
              Administrator.
            </p>
            <p>
              Bitte warten Sie ca. 30 Sekunden, bis Sie die Seite neu Laden und
              zum Projekt zurückkehren
            </p>
          </div>
          <button
            @click="reloadPage"
            class="
              bg-green-600
              hover:bg-green-500
              mr-20
              mb-5
              text-white
              font-bold
              uppercase
              w-full
              mt-5
              text-xs
              px-6
              py-3
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
          >
            Zurück zur Projektübersicht
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import dataService from "../services/dataService";
import jsonLaenderListe from "../assets/countries.json";
import _ from "lodash";
export default {
  name: "ProjectTable",

  watch: {
    searchString: function () {
      this.debouncedOrderedAndFilteredProjects();
    },
    laenderSearchString: function () {
      this.debouncedGetLaender();
    },
    organisationenSearchString: function () {
      this.debouncedGetOrganisationen();
    },
    projects: function () {
      this.debouncedOrderedAndFilteredProjects();
    },
  },

  methods: {
    reloadPage() {
      window.location.reload();
    },

    //CSV aus den aktuellen Daten generieren
    generateCsvFromFilteredData: function () {
      var gefilterteProjekte = this.filteredProjects;
      var neuesCsv = "data:text/csv;charset=utf-8,";
      var maxZahlungen = _.maxBy(gefilterteProjekte, function (o) {
        return o.zahlungen.length;
      });
      maxZahlungen = maxZahlungen.zahlungen.length;

      //HeaderZeile einfügen in CSV
      var projektHeaderRow =
        "Id;Projektnummer;Projektname;Verantwortlicher Name; Verantwortlicher Vorname;Jahr;Land;Kontinent;Projekt aus dem Ausland?;Projektträger Organisation;Projektträger Name;Projektträger Vorname;Projektträger Adresse;Projektträger PLZ; Projektträger Ort; Projektträger Land; Projektträger Email; Projektträger Website";
      var zahlungenHeaderRow = "";
      for (let index = 1; index <= maxZahlungen; index++) {
        zahlungenHeaderRow +=
          ";Zahlung " +
          index +
          " Betrag;" +
          "Zahlung " +
          index +
          " Währung;" +
          "Zahlung " +
          index +
          " Datum Entscheidung;" +
          "Zahlung " +
          index +
          " Status;" +
          "Zahlung " +
          index +
          " Belegnummer;" +
          "Zahlung " +
          index +
          " Zahldatum;" +
          "Zahlung " +
          index +
          " Bemerkung";
      }
      neuesCsv += projektHeaderRow + zahlungenHeaderRow + "\r\n";

      gefilterteProjekte.forEach(function (projekt) {
        projekt.Name = projekt.Name.replace(/;/g, ":");
        projekt.kontaktperson.Organisation =
          projekt.kontaktperson.Organisation.replace(/;/g, ":");
        let projektDaten =
          projekt.id +
          ";" +
          projekt.Projektnummer +
          ";" +
          projekt.Name +
          ";" +
          projekt.VerantwortlicherName +
          ";" +
          projekt.VerantwortlicherVorname +
          ";" +
          projekt.Jahr +
          ";" +
          projekt.land.Name +
          ";" +
          projekt.land.Kontinent +
          ";" +
          (projekt.IsAusland ? "Ja" : "Nein") +
          ";" +
          projekt.kontaktperson.Organisation +
          ";" +
          (projekt.kontaktperson.Name == null
            ? ""
            : projekt.kontaktperson.Name) +
          ";" +
          (projekt.kontaktperson.Vorname == null
            ? ""
            : projekt.kontaktperson.Vorname) +
          ";" +
          (projekt.kontaktperson.Adresse == null
            ? ""
            : projekt.kontaktperson.Adresse) +
          ";" +
          (projekt.kontaktperson.PLZ == null ? "" : projekt.kontaktperson.PLZ) +
          ";" +
          (projekt.kontaktperson.Ort == null ? "" : projekt.kontaktperson.Ort) +
          ";" +
          (projekt.kontaktperson.Land == null
            ? ""
            : projekt.kontaktperson.Land) +
          ";" +
          (projekt.kontaktperson.Email == null
            ? ""
            : projekt.kontaktperson.Email) +
          ";" +
          (projekt.kontaktperson.Website == null
            ? ""
            : projekt.kontaktperson.Website);
        let zahlungen = "";
        projekt.zahlungen.forEach((zahlung) => {
          zahlung.Bemerkung = zahlung.Bemerkung.replace(/;/g, ":");
          let eineZahlung =
            ";" +
            zahlung.Betrag +
            ";" +
            zahlung.Waehrung +
            ";" +
            zahlung.EntschiedenAm +
            ";" +
            zahlung.Status +
            ";" +
            (zahlung.BelegNr == undefined ? "" : zahlung.BelegNr) +
            ";" +
            zahlung.DatumZahlung +
            ";" +
            zahlung.Bemerkung;
          zahlungen += eineZahlung;
        });

        let row = projektDaten + zahlungen;
        neuesCsv += row + "\r\n";
      });
      this.csvContent = neuesCsv;
      const a = document.createElement("a");
      a.href = this.csvContent;
      a.download = "Export_Hilfsprojekte_Verwaltung";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    onCreateProjekt() {
      this.$router.push({ name: "createProjekt" });
    },
    onDetails: function (id) {
      this.$router.push({ name: "detail", params: { id: id } });
    },

    async deleteProjekt() {
      this.showConfirmMessage = false;
      try {
        await dataService.deleteProjekt(this.aktuellesProjekt);
        window.location.reload();
      } catch (error) {
        console.log(error.message);
        this.projektServerResponse = error.message;
      }
    },

    //Zu löschendes Projekt definieren
    sendProjekt(zuloeschendesProjekt) {
      this.aktuellesProjekt = zuloeschendesProjekt;
    },

    triggerPopupLoeschen() {
      //Buttons unsichtbar machen
      this.showPopupLoeschen = !this.showPopupLoeschen;
    },

    getLaenderListe() {
      for (let index = 0; index < jsonLaenderListe.length; index++) {
        this.laenderListe.push(jsonLaenderListe[index]);
      }
    },

    async getProjekte() {
      const response = await dataService.getAlleProjekte();
      //Überprüfung ob Projekt wirklich geladen wurde
      if (response.data.projekte.length == 0) {
        this.sucheFehlgeschlagen = false;
        this.projekteLaden = false;
        this.projekteNichtGeladen = true;
        this.showButtons = false;
      }
      response.data.projekte.forEach((gefundenesProjekt) => {
        if (gefundenesProjekt.zahlungen.length > 0) {
          gefundenesProjekt.Status = this.getStatus(
            gefundenesProjekt.zahlungen
          );
          gefundenesProjekt.Waehrung = gefundenesProjekt.zahlungen[0].Waehrung;
          gefundenesProjekt.Betrag = this.getBetrag(
            gefundenesProjekt.zahlungen
          );
        } else {
          gefundenesProjekt.Status = "Erfasst";
          gefundenesProjekt.Betrag = "";
        }
        if (
          gefundenesProjekt.kontaktperson == null ||
          gefundenesProjekt.kontaktperson.Organisation == null
        ) {
          gefundenesProjekt.kontaktperson = {
            Organisation: "",
          };
        }
        if (
          gefundenesProjekt.land == null ||
          gefundenesProjekt.land.Name == null
        ) {
          gefundenesProjekt.land = {
            Name: "",
          };
        }
      });
      if (response.data.projekte.length > 0) {
        this.projekteLaden = false;
        this.showButtons = true;
      }

      this.projects = response.data.projekte;
    },

    getStatus(zahlungen) {
      for (let index = 0; index < zahlungen.length; index++) {
        const element = zahlungen[index];
        if (element.Status != "Bezahlt") {
          return element.Status;
        }
      }
      return zahlungen[zahlungen.length - 1].Status;
    },

    getBetrag(zahlungen) {
      var betrag = 0;
      for (let index = 0; index < zahlungen.length; index++) {
        betrag += zahlungen[index].Betrag;
      }
      //Betrag mit hochkomma darstellen
      betrag = betrag.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
      if (
        betrag.toString().split(".")[1] != undefined &&
        betrag.toString().split(".")[1].length > 0 &&
        betrag.toString().split(".")[1].length < 2
      ) {
        betrag += "0";
      }
      return betrag;
    },
    orderedAndFilteredProjects: function () {
      this.sucheFehlgeschlagen = false;
      var projekteNew = _.orderBy(this.projects, "Projektnummer", "desc");

      if (this.searchString != "") {
        document.getElementById("archiviertCheckbox").checked = true;
      }

      if (!document.getElementById("archiviertCheckbox").checked) {
        projekteNew = projekteNew.filter(
          (project) => project.Archiviert == false
        );
      }
      if (this.searchString != "") {
        projekteNew = projekteNew.filter(
          (project) =>
            (project.Name != null &&
              project.Name.toLowerCase().includes(
                this.searchString.toLowerCase()
              )) ||
            (project.Status != null &&
              project.Status.toLowerCase().includes(
                this.searchString.toLowerCase()
              )) ||
            (project.kontaktperson.Organisation != null &&
              project.kontaktperson.Organisation.toLowerCase().includes(
                this.searchString.toLowerCase()
              )) ||
            (project.VerantwortlicherName != null &&
              project.VerantwortlicherName.toLowerCase().includes(
                this.searchString.toLowerCase()
              )) ||
            (project.VerantwortlicherVorname != null &&
              project.VerantwortlicherVorname.toLowerCase().includes(
                this.searchString.toLowerCase()
              )) ||
            (project.land.Name != null &&
              project.land.Name.toLowerCase().includes(
                this.searchString.toLowerCase()
              )) ||
            (project.Projektnummer != null &&
              project.Projektnummer.toLowerCase().includes(
                this.searchString.toLowerCase()
              ))
        );
      }
      this.filteredProjects = projekteNew;
      if (this.filteredProjects.length == 0) {
        this.sucheFehlgeschlagen = true;
      }
    },

    loadBackup: function () {
      this.showBackupPopup = true;
    },

    async BackupImportieren() {
      //Datei holen
      var backupFile = document.getElementById("backupFile").files[0];

      //Falls Kein BackupFile Ausgewählt wurde Erinnern dass zuerst ein Backup file importiert werden muss!
      if (backupFile == null) {
        this.textKeineDateiAusgewaehlt = true;
      } else {
        this.textBackupPopUp =
          "Backup wird in die Datenbank geladen. Dies kann einen Moment dauern.";
        this.showbackupNormaleAnsicht = false;
        //LadeIcon anzeigen
        this.showBackupLoadingIcon = true;

        var jsonBackup = await this.getTextFromJSONFile(backupFile);
        jsonBackup = JSON.parse(jsonBackup);

        //Daten dem DataService übergeben
        try {
          await dataService.restoreBackup(jsonBackup);

          //LadeIcon ausblenden
          this.showBackupLoadingIcon = false;
          //Popup schliessen
          this.showBackupPopup = false;
          //Seite neu laden
          window.location.reload();
        } catch (error) {
          this.projektServerResponse = error.message;
        }
      }
    },

    triggerPopup() {
      this.showBackupPopup = !this.showBackupPopup;
    },

    changeBoolean() {
      this.oberesDivInBackupPopUP = false;
    },

    getTextFromJSONFile: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }),

    async BackupDownload() {
      this.textBackupPopUp =
        "Backup wird heruntergeladen. Dies kann einen Moment dauern.";
      this.showbackupNormaleAnsicht = false;
      this.showBackupLoadingIcon = true;

      var backupdata = await dataService.getAlleProjekte();
      backupdata = JSON.stringify(backupdata.data);

      //Timestamp für Filename
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
      var dateTime = date + "-" + time;

      const file = new Blob([backupdata], { type: "text/json;charset=utf-8" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(file);
      a.download = "Backup_Hilfsprojekte_Verwaltung-" + dateTime + ".json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      //LadeIcon ausblenden
      this.showBackupLoadingIcon = false;
      //Popup schliessen
      this.showBackupPopup = false;
      //Seite neu laden
      window.location.reload();
    },
  },

  data() {
    return {
      //show Popup für Backup File
      showBackupPopup: false,
      //LadeIcon für Backup
      showBackupLoadingIcon: false,
      showbackupNormaleAnsicht: true,
      filterLeer: false,
      projekteLaden: false,
      projekteNichtGeladen: false,
      sucheFehlgeschlagen: false,
      showButtons: true,
      projektDatenGeholt: false,
      oberesDivInBackupPopUP: true,
      nameFilter: "Alle Projekte",

      textKeineDateiAusgewaehlt: false,
      textBackupPopUp: "",
      projects: [],
      laenderListe: [],
      searchString: "",
      laenderSearchString: "",
      organisationenSearchString: "",
      filteredProjects: [],
      filteredLaender: [],
      filteredKontinente: [],
      filteredJahre: [],
      filteredStatus: [],
      csvContent: [],
      filteredOrganisationen: [],
      showFilterDropdown: false,
      showPopupLoeschen: false,
      aktuellesProjekt: null,
      showConfirmMessage: true,
      projektServerResponse: "",
      buttons: true,
      showFilterLand: false,
    };
  },

  mounted() {
    this.projekteLaden = true;
    this.sucheFehlgeschlagen = false;
    this.showButtons = false;
    document
      .getElementById("hilfsprojekteSidebar")
      .classList.add("bg-green-700");
    //document.getElementById("mitgliederSidebar").classList.remove("bg-green-700");
    this.getLaenderListe();
    this.getProjekte();
    this.debouncedOrderedAndFilteredProjects = _.debounce(
      this.orderedAndFilteredProjects,
      400
    );
  },
};
</script>

<style scoped>
.toggle-checkbox:checked {
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  background-color: #68d391;
}
.Bezahlt {
  background-color: #66bb6a !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Abgelehnt {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Abgeschrieben {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Erfasst {
  background-color: #bdbdbd !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Bewilligt {
  background-color: #dce775 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.Ausstehend {
  background-color: #dce775 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.nicht-bearbeitet-status {
  background-color: #ff7043;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.true {
  background-color: #dce775;
}

.popup {
  animation: drop 0.5s ease forwards;
}
@keyframes drop {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY();
    opacity: 1;
  }
}
</style>
    










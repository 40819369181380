<template>
  <div>
    <div>
      <div class="w-full lg:w-10/12 px-4 mx-auto mt-6">
        <div
          class="
            relative
            flex flex-col
            min-w-0
            break-words
            w-full
            mb-6
            shadow-lg
            rounded-lg
            bg-gray-100
            border-0
            pb-8
          "
        >
          <Form @submit="onProjektSpeichern" :validation-schema="projektSchema">
            <div class="rounded-t bg-gray-100 mb-0 px-6 py-6">
              <div class="text-center flex justify-between">
                <h6 class="text-blueGray-700 text-xl font-bold">
                  Neues Projekt erstellen
                </h6>
              </div>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="relative">
                <div class="absolute top-0 right-0"></div>
                <div class="flex justify-between">
                  <div
                    class="
                      text-blueGray-400 text-sm
                      mt-3
                      mb-6
                      font-bold
                      uppercase
                    "
                  >
                    Projekt Informationen
                  </div>
                </div>
                <div>
                  <fieldset>
                    <div class="flex flex-wrap">
                      <!-- Projektname Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Projektname
                          </label>

                          <Field
                            type="text"
                            name="projektName"
                            id="projektName"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.Name"
                          />
                          <ErrorMessage
                            name="projektName"
                            class="error-feedback"
                          />
                        </div>
                      </div>
                      <!-- Projektnummer Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Projektnummer
                          </label>
                          <Field
                            type="text"
                            name="projektNummer"
                            id="projektNummer"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.Projektnummer"
                          />
                          <ErrorMessage
                            name="projektNummer"
                            class="error-feedback"
                          />
                        </div>
                      </div>
                      <!-- Projektverantwortliche/r Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Projektverantwortliche/r Name
                          </label>
                          <Field
                            type="text"
                            name="projektVerantwortlicher"
                            id="projektVerantwortlicher"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.VerantwortlicherName"
                          />
                          <ErrorMessage
                            name="projektVerantwortlicher"
                            class="error-feedback"
                          />
                        </div>
                      </div>
                      <!-- Projektverantwortliche/r Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Projektverantwortliche/r Vorname
                          </label>
                          <Field
                            type="text"
                            name="projektVerantwortlicherVorname"
                            id="projektVerantwortlicherVorname"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.VerantwortlicherVorname"
                          />
                          <ErrorMessage
                            name="projektVerantwortlicher"
                            class="error-feedback"
                          />
                        </div>
                      </div>
                      <!-- Projekt Eingangsdatum Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-4">
                          <label class="block uppercase text-xs font-bold mb-2">
                            Eingangsdatum des Projektes
                          </label>
                          <Field
                            type="date"
                            name="eingangsdatum"
                            class="
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              bg-white
                              rounded
                              text-sm
                              shadow-md
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.Eingangsdatum"
                          />
                          <ErrorMessage
                            name="eingangsdatum"
                            class="error-feedback"
                          />
                        </div>
                      </div>
                      <!-- Projektjahr Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Jahr
                          </label>

                          <!-- TODO: Überprüfung ob Jahr 4 Stellig nur Zahlen -->
                          <Field
                            type="number"
                            name="projektJahr"
                            id="projektJahr"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.Jahr"
                          />
                          <ErrorMessage
                            name="projektJahr"
                            class="error-feedback"
                          />
                        </div>
                      </div>
                      <!-- Projekt Land Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Land
                          </label>
                          <!-- <input type="text" id="land" class="textfeld border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" v-model = "project.land.Name" > -->
                          <div class="dropdown">
                            <input
                              v-if="Object.keys(selectedLand).length === 0"
                              @focus="changeDropdownVisibility"
                              @blur="changeDropdownVisibility"
                              ref="dropdowninput"
                              v-model.trim="landSearchString"
                              class="
                                self-start
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-blueGray-600
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                              type="text"
                              placeholder="Länder durchsuchen ..."
                            />
                            <div
                              v-else
                              @click="resetLand"
                              class="
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-gray-500
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                            >
                              <div class="flex flex-row items-center">
                                <span>{{ selectedLand.name }}</span>
                              </div>
                            </div>
                            <div
                              v-show="showLandDropdown"
                              class="dropdown-list z-20"
                            >
                              <div
                                @click="selectItem(item)"
                                v-show="itemVisible(item)"
                                v-for="item in laenderListe"
                                :key="item.name"
                                class="dropdown-item"
                              >
                                <div class="flex flex-row items-center">
                                  <span>{{ item.name }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Projekt Kontinent Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label class="block uppercase text-xs font-bold mb-2">
                            Kontinent
                          </label>
                          <select
                            class="
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              bg-white
                              rounded
                              text-sm
                              shadow-md
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.land.Kontinent"
                          >
                            <option>Asien</option>
                            <option>Afrika</option>
                            <option>Nordamerika</option>
                            <option>Mittelamerika</option>
                            <option>Südamerika</option>
                            <option>Europa</option>
                            <option>Australien</option>
                          </select>
                        </div>
                      </div>
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="mt-2">
                          <div>
                            <label
                              class="
                                block
                                uppercase
                                text-blueGray-600 text-xs
                                font-bold
                                mb-2
                              "
                            >
                              Projekt in
                            </label>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radio"
                                value="false"
                                checked
                                v-model="projekt.IsAusland"
                              />
                              <span class="ml-2">Schweiz</span>
                            </label>
                          </div>
                          <div>
                            <label class="inline-flex items-center">
                              <input
                                type="radio"
                                class="form-radio"
                                name="radio"
                                value="true"
                                v-model="projekt.IsAusland"
                              />
                              <span class="ml-2">Ausland</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr class="mt-6 border-b-1 border-gray-400" />

                    <h6
                      class="
                        text-blueGray-400 text-sm
                        mt-3
                        mb-6
                        font-bold
                        uppercase
                      "
                    >
                      Projektträger / Kontaktperson Informationen
                    </h6>
                    <div class="flex flex-wrap">
                      <!-- Organisation Name Input -->
                      <div class="w-full lg:w-6/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Organisation
                          </label>
                          <Field
                            id="organisation"
                            name="organisation"
                            type="text"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.kontaktperson.Organisation"
                          />
                          <ErrorMessage
                            name="organisation"
                            class="error-feedback"
                          />
                        </div>
                      </div>
                      <!-- Nachname Projektträger Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Nachname
                          </label>
                          <Field
                            id="name"
                            name="name"
                            type="text"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.kontaktperson.Name"
                          />
                          <ErrorMessage name="name" class="error-feedback" />
                        </div>
                      </div>
                      <!-- Vorname Projektträger Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Vorname
                          </label>
                          <Field
                            id="vorname"
                            name="vorname"
                            type="text"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.kontaktperson.Vorname"
                          />
                          <ErrorMessage name="vorname" class="error-feedback" />
                        </div>
                      </div>
                      <!-- Adresse Projektträger Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Adresse
                          </label>
                          <Field
                            type="text"
                            name="adresse"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.kontaktperson.Adresse"
                          />
                          <ErrorMessage name="adresse" class="error-feedback" />
                        </div>
                      </div>
                      <!-- PLZ Projektträger Input -->
                      <!-- TODO Regel einfügen für PLZ? -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            PLZ
                          </label>
                          <Field
                            type="text"
                            name="plz"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.kontaktperson.PLZ"
                          />
                          <ErrorMessage name="plz" class="error-feedback" />
                        </div>
                      </div>
                      <!-- Ort Projektträger Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Ort
                          </label>
                          <Field
                            type="text"
                            name="ort"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.kontaktperson.Ort"
                          />
                          <ErrorMessage name="ort" class="error-feedback" />
                        </div>
                      </div>
                      <!-- Land Projektträger Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Land
                          </label>
                          <div class="dropdown">
                            <input
                              v-if="
                                Object.keys(selectedLandOrganisation).length ===
                                0
                              "
                              @focus="changeDropdownVisibilityOrganisation"
                              @blur="changeDropdownVisibilityOrganisation"
                              ref="dropdowninputOrganisation"
                              v-model.trim="landSearchStringOrganisation"
                              class="
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-blueGray-600
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                              type="text"
                              placeholder="Länder durchsuchen ..."
                            />
                            <div
                              v-else
                              @click="resetLandOrganisation"
                              class="
                                textfeld
                                border-0
                                px-3
                                py-3
                                placeholder-blueGray-300
                                text-gray-500
                                bg-white
                                rounded
                                text-sm
                                shadow
                                focus:outline-none focus:ring
                                w-full
                                ease-linear
                                transition-all
                                duration-150
                              "
                            >
                              <div class="flex flex-row items-center">
                                <span>{{ selectedLandOrganisation.name }}</span>
                              </div>
                            </div>
                            <div
                              v-show="showLandDropdownOrganisation"
                              class="dropdown-list z-20"
                            >
                              <div
                                @click="selectItemOrganisation(item)"
                                v-show="itemVisibleOrganisation(item)"
                                v-for="item in laenderListeOrganisation"
                                :key="item.name"
                                class="dropdown-item"
                              >
                                <div class="flex flex-row items-center">
                                  <span>{{ item.name }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Email Projektträger Input -->
                      <div class="w-full lg:w-3/12 px-4 mb-3">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            E-Mail
                          </label>
                          <Field
                            name="email"
                            id="email"
                            type="email"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.kontaktperson.Email"
                          />
                          <ErrorMessage name="email" class="error-feedback" />
                        </div>
                      </div>
                      <!-- Website Projektträger Input -->
                      <div class="w-full lg:w-3/12 px-4">
                        <div class="relative w-full mb-3">
                          <label
                            class="
                              block
                              uppercase
                              text-blueGray-600 text-xs
                              font-bold
                              mb-2
                            "
                          >
                            Website
                          </label>
                          <Field
                            type="text"
                            name="website"
                            class="
                              textfeld
                              border-0
                              px-3
                              py-3
                              placeholder-blueGray-300
                              text-blueGray-600
                              bg-white
                              rounded
                              text-sm
                              shadow
                              focus:outline-none focus:ring
                              w-full
                              ease-linear
                              transition-all
                              duration-150
                            "
                            v-model="projekt.kontaktperson.Website"
                          />
                          <ErrorMessage name="website" class="error-feedback" />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div class="relative">
                  <div class="absolute top-0 right-0">
                    <button
                      @click="onZahlungHinzufuegen()"
                      class="
                        mt-4
                        mr-4
                        mb-3
                        hidden
                        md:table-cell
                        bg-green-600
                        hover:bg-green-500
                        text-white text-xs
                        font-bold
                        py-2
                        px-4
                        rounded
                        inline-flex
                        items-center
                        uppercase
                      "
                    >
                      <span>Weitere Zahlung hinzufügen</span>
                    </button>
                  </div>
                  <form id="zahlungform">
                    <fieldset>
                      <div v-for="zahlung in this.zahlungen" :key="zahlung.id">
                        <hr class="border-b-1 border-gray-400" />
                        <h6
                          class="
                            text-blueGray-400 text-sm
                            mt-3
                            mb-6
                            font-bold
                            uppercase
                          "
                        >
                          Zahlung {{ zahlung.id }}
                        </h6>

                        <div class="flex flex-wrap mt-3">
                          <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block uppercase text-xs font-bold mb-2"
                              >
                                Betrag
                              </label>
                              <input
                                type="number"
                                min="0"
                                class="
                                  border-0
                                  px-3
                                  py-3
                                  placeholder-blueGray-300
                                  bg-white
                                  rounded
                                  text-sm
                                  shadow-md
                                  focus:outline-none focus:ring
                                  w-full
                                  ease-linear
                                  transition-all
                                  duration-150
                                "
                                v-model="zahlung.Betrag"
                              />
                            </div>
                          </div>
                          <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block uppercase text-xs font-bold mb-2"
                              >
                                Währung
                              </label>
                              <select
                                class="
                                  border-0
                                  px-3
                                  py-3
                                  placeholder-blueGray-300
                                  bg-white
                                  rounded
                                  text-sm
                                  shadow-md
                                  focus:outline-none focus:ring
                                  w-full
                                  ease-linear
                                  transition-all
                                  duration-150
                                "
                                v-model="zahlung.Waehrung"
                              >
                                <option>CHF</option>
                                <option>USD</option>
                                <option>Euro</option>
                              </select>
                            </div>
                          </div>
                          <div class="w-full lg:w-4/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block uppercase text-xs font-bold mb-2"
                              >
                                Entschieden am
                              </label>
                              <input
                                type="date"
                                class="
                                  border-0
                                  px-3
                                  py-3
                                  placeholder-blueGray-300
                                  bg-white
                                  rounded
                                  text-sm
                                  shadow-md
                                  focus:outline-none focus:ring
                                  w-full
                                  ease-linear
                                  transition-all
                                  duration-150
                                "
                                v-model="zahlung.EntschiedenAm"
                              />
                            </div>
                          </div>

                          <div class="w-full lg:w-6/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="
                                  block
                                  uppercase
                                  text-blueGray-600 text-xs
                                  font-bold
                                  mb-2
                                "
                              >
                                Status
                              </label>
                              <select
                                class="
                                  border-0
                                  px-3
                                  py-3
                                  placeholder-blueGray-300
                                  text-blueGray-600
                                  bg-white
                                  rounded
                                  text-sm
                                  shadow
                                  focus:outline-none focus:ring
                                  w-full
                                  ease-linear
                                  transition-all
                                  duration-150
                                "
                                v-model="zahlung.Status"
                              >
                                <option>Erfasst</option>
                                <option>Bewilligt</option>
                                <option>Abgelehnt</option>
                                <option>Bezahlt</option>
                                <option>Ausstehend</option>
                                <option>Nicht bearbeitet</option>
                                <option>Abgeschrieben</option>
                              </select>
                            </div>
                          </div>
                          <div class="w-full lg:w-12/12 px-4">
                            <div class="relative w-full mb-3">
                              <label
                                class="block uppercase text-xs font-bold mb-2"
                              >
                                Bemerkung
                              </label>
                              <input
                                type="text"
                                class="
                                  border-0
                                  px-3
                                  py-3
                                  placeholder-blueGray-300
                                  bg-white
                                  rounded
                                  text-sm
                                  shadow-md
                                  focus:outline-none focus:ring
                                  w-full
                                  ease-linear
                                  transition-all
                                  duration-150
                                "
                                v-model="zahlung.Bemerkung"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            <div class="absolute bottom-0 left-0 lg:p-0 p-2">
              <button
                @click="onAbbrechen()"
                class="
                  mb-8
                  ml-14
                  bg-red-600
                  hover:bg-red-500
                  text-white text-xs
                  font-bold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                  uppercase
                "
              >
                <span>Projekterstellung abbrechen</span>
              </button>
            </div>
            <div class="absolute bottom-0 right-0">
              <button
                class="
                  mb-1
                  lg:mb-8
                  mr-14
                  bg-green-600
                  hover:bg-green-500
                  text-white text-xs
                  font-bold
                  py-2
                  px-4
                  rounded
                  inline-flex
                  items-center
                  uppercase
                "
                type="submit"
              >
                <span>Neues Projekt abspeichern</span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <!-- Pop UP Projektänderung werden gespeichert-->
    <div
      v-if="showPopupSpeichern"
      class="
        bg-black
        fixed
        inset-0
        flex
        items-center
        justify-center
        bg-opacity-70
        text-black
      "
    >
      <div class="bg-white p-12 rounded-md relative">
        <div v-if="!projektServerResponse && !showButtons">
          {{ aenderungsPopUpText }}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-10 w-10 ml-28 animate-spin mt-5 text-green-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </div>
        <div v-if="projektServerResponse" role="alert">
          <div
            class="
              bg-red-500
              text-white
              font-bold
              rounded-t
              px-4
              py-2
              text-center
            "
          >
            Leider ist ein Fehler aufgetreten.
          </div>
          <div
            class="
              border border-t-0 border-red-400
              rounded-b
              bg-red-100
              px-4
              py-3
              text-red-700
            "
          >
            <p class="text-center">Folgender Fehler ist aufgetreten:</p>
            <p class="text-center font-bold">{{ projektServerResponse }}</p>
            <p>
              Versuchen Sie es später erneut, oder melden Sie den Fehler dem
              Administrator.
            </p>
            <p>
              Bitte warten Sie ca. 30 Sekunden, bis Sie zur Projektübersicht
              zurückkehren.
            </p>
          </div>
          <button
            @click="onAbbrechen()"
            class="
              bg-green-600
              hover:bg-green-500
              mr-20
              mb-5
              text-white
              font-bold
              uppercase
              w-full
              mt-5
              text-xs
              px-6
              py-3
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
          >
            Zurück zur Projektübersicht
          </button>
        </div>
        <div v-if="showButtons">
          Projekt wurde erfolgreich abgespeichert!
          <div class="mt-2">
            <button
              id="buttonVerwerfen"
              @click="onNeuesProjektAnlegen()"
              class="
                mr-24
                mb-0
                bg-green-700
                hover:bg-green-600
                text-white
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Weiteres Projekt anlegen
            </button>

            <button
              @click="onAbbrechen()"
              class="
                mb-0
                bg-green-700
                hover:bg-green-600
                text-white
                font-bold
                uppercase
                text-xs
                px-12
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Zurück zur Projektübersicht
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataService from "../services/dataService";
import jsonLaenderListe from "../assets/countries.json";

/* Forms und Validation libs importieren */
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "ProjectDetailForm",

  /* Komponenten für Form und Validierung */
  components: {
    Form,
    Field,
    ErrorMessage,
  },

  data() {
    /* Einstellungen der Form als Schema */
    const projektSchema = yup.object().shape({
      projektName: yup
        .string()
        .required("Der Projektname darf nicht leer sein!"),
      projektNummer: yup
        .string()
        .required("Die Projektnummer darf nicht leer sein!")
        .matches(
          /[0-9][0-9][0-9][0-9].[0-9][0-9]/,
          "Die Projektnummer muss im folgenden Format erfasst werden: XXXX.XX (Beispiel: 2021.01)"
        ),
      projektJahr: yup
        .number()
        .required("Das Projektjahr darf nicht leer sein!")
        .positive()
        .max(9999, "Bitte das Jahr im Format JJJJ eingeben (Bsp. 2021)")
        .min(1000, "Bitte das Jahr im Format JJJJ eingeben (Bsp. 2021)")
        .typeError("Das Projektjahr darf nicht leer sein!"),
      email: yup
        .string()
        .email("Bitte eine gültige Email Adresse eingeben")
        .nullable(),
    });
    return {
      /* Das Schema definiert die Einstellungen der Form */
      projektSchema,
      /* Message bzw. Error vom Server welche angezeigt werden kann */
      projektServerResponse: "",

      aenderungsPopUpText: "",

      showPopupSpeichern: false,
      showButtons: false,
      selectedLand: {},
      landSearchString: "",
      laenderListe: [],
      showLandDropdown: false,

      selectedLandOrganisation: {},
      landSearchStringOrganisation: "",
      showLandDropdownOrganisation: false,
      laenderListeOrganisation: [],

      projekt: {
        Name: "",
        Projektnummer: "",
        VerantwortlicherName: "",
        Jahr: "",
        IsAusland: "",
        land: {
          Name: "",
          Kontinent: "",
        },
        kontaktperson: {
          Name: "",
          Vorname: "",
          Email: "",
          Organisation: "",
          PLZ: "",
          Adresse: "",
          Land: "",
          Website: "",
          Ort: "",
        },
      },
      zahlungen: [
        {
          Betrag: 0,
          Waehrung: "",
          Status: "Erfasst",
          Bemerkung: "",
          Archiviert: false,
          EntschiedenAm: "",
          id: 1,
        },
      ],
    };
  },

  async mounted() {
    this.getLaenderList();
    this.getLaenderListOrganisation();
    this.selectedLandOrganisation = await this.laenderListeOrganisation.find(
      (land) => {
        return land.name == "Schweiz";
      }
    );
  },

  methods: {
    /* Methoden für die Landliste der Projekte */
    resetLand() {
      if (!this.showProjektBearbeiten) {
        this.selectedLand = {};
        this.$nextTick(() => this.$refs.dropdowninput.focus());
        this.$emit("on-item-reset");
      }
    },
    changeDropdownVisibility() {
      setTimeout(() => {
        if (this.selectedLand != {})
          this.showLandDropdown = !this.showLandDropdown;
      }, 200);
    },
    selectItem(theItem) {
      setTimeout(() => {
        this.selectedLand = theItem;
        this.landSearchString = "";
        this.$emit("on-item-selected", theItem);
        this.showLandDropdown = false;
      }, 200);
    },
    itemVisible(item) {
      let currentName = item.name.toLowerCase();
      let currentInput = this.landSearchString.toLowerCase();
      return currentName.startsWith(currentInput);
    },
    getLaenderList() {
      for (let index = 0; index < jsonLaenderListe.length; index++) {
        this.laenderListe.push(jsonLaenderListe[index]);
      }
    },

    /* Methoden für die Landliste von Organisationen */
    /* Methoden für die Landliste der Projekte */
    resetLandOrganisation() {
      if (!this.showProjektBearbeiten) {
        this.selectedLandOrganisation = {};
        this.$nextTick(() => this.$refs.dropdowninputOrganisation.focus());
        this.$emit("on-item-reset");
      }
    },
    changeDropdownVisibilityOrganisation() {
      setTimeout(() => {
        if (this.selectedLandOrganisation != {})
          this.showLandDropdownOrganisation =
            !this.showLandDropdownOrganisation;
      }, 200);
    },
    selectItemOrganisation(theItem) {
      setTimeout(() => {
        this.selectedLandOrganisation = theItem;
        this.landSearchStringOrganisation = "";
        this.$emit("on-item-selected", theItem);
        this.showLandDropdownOrganisation = false;
      }, 200);
    },
    itemVisibleOrganisation(item) {
      let currentName = item.name.toLowerCase();
      let currentInput = this.landSearchStringOrganisation.toLowerCase();
      return currentName.startsWith(currentInput);
    },
    getLaenderListOrganisation() {
      for (let index = 0; index < jsonLaenderListe.length; index++) {
        this.laenderListeOrganisation.push(jsonLaenderListe[index]);
      }
    },
    async onProjektSpeichern() {
      /* Neues Projekt erstellen, welches dann mitgeschickt wird, damit nicht das gebindete verändert werden muss */
      this.showPopupSpeichern = true;
      this.aenderungsPopUpText = "Neues Projekt wird gespeichert ...";

      var tempProjectForDataService = Object.assign({}, this.projekt)
      tempProjectForDataService.land = Object.assign({}, this.projekt.land)
      tempProjectForDataService.kontaktperson = Object.assign({}, this.projekt.kontaktperson)

      /* Das ausgewählte Land aus der Liste dem Projekt zuweisen */
      if (this.selectedLand != {}) {
        if (this.selectedLand.name === undefined) {
          tempProjectForDataService.land.Name = null;
        } else {
          tempProjectForDataService.land.Name = this.selectedLand.name;
        }
      } else {
        /* Es wurde kein Land und kein Kontinent ausgewählt */
        if (tempProjectForDataService.land.Kontinent == "") {
          tempProjectForDataService.land = null;
        }
      }

      /* Das ausgewählte Land der Organisationen der Kontaktperson zuweisen */
      if (this.selectedLandOrganisation != {}) {
        if (this.selectedLandOrganisation.name === undefined) {
          tempProjectForDataService.kontaktperson.Land = null;
        } else {
          tempProjectForDataService.kontaktperson.Land =
            this.selectedLandOrganisation.name;
        }
      } else {
        /* Es wurde kein Land ausgewählt */
        tempProjectForDataService.kontaktperson.Land = null;
      }

      /*leere kPerson als null senden */
      //TODO Land überprüfung mit Objekt aus selectedLandOrga
      if (
        (tempProjectForDataService.kontaktperson.Name == "" ||
          tempProjectForDataService.kontaktperson.Name == null) &&
        (tempProjectForDataService.kontaktperson.Vorname == "" ||
          tempProjectForDataService.kontaktperson.Vorname == null) &&
        (tempProjectForDataService.kontaktperson.Email == "" ||
          tempProjectForDataService.kontaktperson.Email == null) &&
        (tempProjectForDataService.kontaktperson.Ort == "" ||
          tempProjectForDataService.kontaktperson.Ort == null) &&
        (tempProjectForDataService.kontaktperson.Organisation == "" ||
          tempProjectForDataService.kontaktperson.Organisation == null) &&
        (tempProjectForDataService.kontaktperson.PLZ == "" ||
          tempProjectForDataService.kontaktperson.PLZ == null) &&
        (tempProjectForDataService.kontaktperson.Website == "" ||
          tempProjectForDataService.kontaktperson.Website == null) &&
        (tempProjectForDataService.kontaktperson.Adresse == "" ||
          tempProjectForDataService.kontaktperson.Adresse == null) &&
        (tempProjectForDataService.kontaktperson.Land == "" ||
          tempProjectForDataService.kontaktperson.Land == null)
      ) {
        tempProjectForDataService.kontaktperson = null;
      }

      try {
        var response = await dataService.createProjekt(
          tempProjectForDataService
        );
        var projektId = response.data.id;

        //Zahlungen abspeichern
        for (var i = 0; i < this.zahlungen.length; i++) {
          this.zahlungen[i].projektId = projektId;
          var zahlung = this.zahlungen[i];
          await dataService.createZahlung(zahlung);
        }
        this.showButtons = true;
      } catch (error) {
        this.projektServerResponse = error.message;
      }
    },

    onAbbrechen() {
      this.$router.push("/hilfsprojekte");
    },

    onNeuesProjektAnlegen() {
      window.location.reload();
    },

    onZahlungHinzufuegen() {
      var zahlung = {
        Betrag: 0,
        Waehrung: "",
        Status: "",
        Bemerkung: "",
        Archiviert: false,
        id: this.zahlungen.length + 1,
      };
      this.zahlungen.push(zahlung);
    },
  },
};
</script>




<style scoped>
input {
  color: black;
}

.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder {
  opacity: 0.7;
}
.dropdown-selected {
  cursor: pointer;
}
.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 300px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}
.dropdown-item:hover {
  background: #edf2f7;
}
</style>

<template>



<ProjectDetailForm/>


    

</template>


<script>
import ProjectDetailForm from '../components/ProjectDetailForm'


export default {
 name: 'HilfsprojekteDetail',
     components: {
         ProjectDetailForm
     }
}
     
</script>



<style scoped>

</style>
<template>
  <div>
    <div>
      <div class="sm:-mx-6 md:-mx-8">
        <div
          id="stickyHeaderButtons"
          class="
            justify-items-stretch
            pt-2
            place-items-baseline
            grid grid-cols-12
            gap-2
            z-20
            sticky
            top-0
            bg-white
          "
        >
          <div
            v-if="showButtons"
            class="
              place-self-center
              col-start-1 col-span-6
              row-start-1
              md:col-start-1 md:col-span-5
              lg:col-start-1 lg:col-span-5
              md:row-start-1
            "
          >
            <button
              @click="loadBackup()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                rounded
                inline-flex
                items-center
              "
            >
              <span class="text-xs md:text-base">Backup</span>
            </button>
          </div>
          <!-- Searchbar -->
          <div
            v-if="showButtons"
            class="
              col-start-1 col-span-12
              row-start-1
              md:col-start-2 md:col-span-5
              lg:col-start-4 lg:col-span-4
              md:row-start-1
            "
          >
            <div class="bg-gray-100 flex items-center rounded-lg shadow-md">
              <input
                class="
                  rounded-l-full
                  w-full
                  py-2
                  px-4
                  md:py-4 md:px-10
                  bg-gray-100
                  text-gray-700
                  md:text-lg
                  text-xs
                  leading-tight
                  focus:outline-none
                "
                id="search"
                type="text"
                v-model="searchString"
                placeholder="Suchen ..."
              />

              <div class="p-2 md:p-2.5">
                <button
                  class="
                    bg-green-600
                    text-white
                    rounded-full
                    p-2
                    hover:bg-green-500
                    focus:outline-none
                    w-7
                    h-7
                    md:w-10 md:h-10
                    flex
                    items-center
                    justify-center
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <!-- Searchbar Ende -->
          <!-- Neues Mitglied-->
          <div
            v-if="showButtons"
            class="
              place-self-start
              md:place-self-center
              col-start-1 col-span-6
              row-start-2
              md:col-start-7 md:col-span-5 md:row-start-1
              flex flex-col
              inline-flex
              items-center
            "
          >
            <button
              @click="onCreateMitglied()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                rounded
                inline-flex
                items-center
              "
            >
              <span class="text-xs md:text-base">Neues Mitglied erstellen</span>
            </button>
          </div>
          <div
            v-if="showButtons"
            class="
              place-self-center
              col-end-12
              md:col-end-13
              row-start-2
              md:col-span-2 md:row-start-1
              flex flex-col
              inline-flex
              items-center
            "
          >
            <button
              @click="generateCsvFromFilteredData()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                rounded
                inline-flex
                items-center
              "
            >
              <span class="text-xs md:text-base">Daten exportieren</span>
            </button>
          </div>

          <!-- Thead sticky -->
          <div v-if="mitglieder.length" class="col-start-1 row-start-3 md:row-start-2 col-span-12">
            <div class="align-middle inline-block min-w-full sm:px-6 md:px-8">
              <div class="shadow-lg overflow-auto border-gray-500 rounded-t-lg">
                <table
                  class="
                    max-w-full
                    divide-y divide-gray-200
                    table-fixed
                    w-full
                    sm:overflow-x-scroll
                  "
                >
                  <thead class="bg-gray-200 max-h-10">
                    <tr>
                      <th
                        scope="col"
                        class="
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                          px-1
                          py-4
                          md:px-3 md:py-3 md:w-4/12
                          w-4/12
                        "
                      >
                        Organisationsname
                      </th>
                      <th
                        scope="col"
                        class="
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                          px-12
                          py-4
                          md:px-2 md:py-3 md:w-2/12
                          w-3/12
                        "
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        class="
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                          md:px-1 md:py-3 md:w-2/12
                          w-3/12
                          px-8
                        "
                      >
                        Vorname
                      </th>

                      <th
                        scope="col"
                        class="
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          md:px-1 md:py-3 md:w-1/12
                          tracking-wider
                          hidden
                          md:table-cell
                        "
                      >
                        PLZ
                      </th>

                      <th
                        scope="col"
                        class="
                          md:px-2 md:py-3 md:w-1/12
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                          hidden
                          md:table-cell
                        "
                      >
                        Ort
                      </th>
                      <th
                        scope="col"
                        class="
                          md:px-3 md:py-3 md:w-1/12
                          text-left text-xs
                          font-medium
                          text-gray-800
                          uppercase
                          tracking-wider
                          hidden
                          md:table-cell
                        "
                      >
                        Aktivmitglied
                      </th>

                      <th
                        scope="col"
                        class="
                          relative
                          md:w-2/12
                          px-1
                          py-2
                          md:px-3 md:py-3
                          w-2/12
                        "
                      >
                        <span class="sr-only">Details</span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!--Gehört in DIV v-if="filteredProjects.length || searchString == ''" -->
        <div
          id="mitgliedTableBody"
          class="sm:h-screen md:h-auto"
          v-if="filteredMitglieder.length || searchString == ''"
        >
          <div class="align-middle inline-block min-w-full sm:px-6 md:px-8">
            <div class="shadow-lg overflow-auto border-gray-500 rounded-b-lg">
              <table
                class="max-w-screen divide-y divide-gray-200 table-fixed w-full"
              >
                <!-- px= leftright py= top bottom-->
                <tbody
                  class="
                    project
                    bg-white
                    divide-y divide-gray-200
                    text-gray-800 text-sm
                    break-words
                  "
                >
                  <!---->
                  <tr v-for="mitglied in filteredMitglieder" :key="mitglied.id">
                    <td
                      class="
                        px-2
                        py-4
                        md:py-4 md:px-4 md:w-4/12
                        w-4/12
                        md:table-cell
                      "
                    >
                      <!--Nur Binden wenn es auch wirklich eine Organisation hat -->
                      <div v-if="mitglied.Organisation">
                        {{ mitglied.Organisation.Name }}
                      </div>
                    </td>

                    <td
                      class="
                        px-6
                        py-4
                        md:px-2 md:py-3 md:w-2/12
                        w-3/12
                        md:table-cell
                      "
                    >
                      <!--Wenn 3 Personen erfasst werden, wird Laden der Mitglieder Failen.... -->
                      <div
                        v-for="mitglied in mitglied.Personen"
                        :key="mitglied.id"
                      >
                        {{ mitglied.Name }}
                      </div>
                    </td>

                    <td
                      class="
                        px-8
                        py-4
                        md:px-1 md:py-3 md:w-2/12
                        w-3/12
                        md:table-cell
                      "
                    >
                      <div
                        v-for="mitglied in mitglied.Personen"
                        :key="mitglied.id"
                      >
                        {{ mitglied.Vorname }}
                      </div>
                    </td>

                    <td
                      class="px-1 py-2 md:py-4 md:w-1/12 hidden md:table-cell"
                    >
                      <div>{{ mitglied.PLZ }}</div>
                    </td>

                    <td
                      class="
                        px-1
                        py-2
                        md: md:py-4 md:w-1/12
                        hidden
                        md:table-cell
                      "
                    >
                      <div>{{ mitglied.Ort }}</div>
                    </td>
                    <td
                      class="
                        px-1
                        py-2
                        md:px-6 md:py-4 md:w-1/12
                        hidden
                        md:table-cell
                      "
                    >
                      {{ mitglied.IsAktivmitglied ? "Ja" : "Nein" }}
                    </td>

                    <td
                      class="
                        px-1
                        py-2
                        md:px-3 md:py-4
                        whitespace-nowrap
                        text-right text-sm
                        font-medium
                        md:w-2/12
                        w-2/12
                      "
                    >
                      <a
                        @click="onDetails(mitglied.id)"
                        class="
                          cursor-pointer
                          mr-6
                          text-indigo-600
                          hover:text-indigo-900
                        "
                        >Details</a
                      >
                      <button
                        class="w-6 h-8 ml-2 hidden md:table-cell"
                        @click="triggerPopupLoeschen(), sendMitglied(mitglied)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 text-red-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          v-if="sucheFehlgeschlagen && !mitgliederNichtGeladen"
          class="grid place-items-center h-full mt-20"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-40 w-40"
              viewBox="0 0 20 20"
              fill="#FBBF24"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>
            <span class="text-2xl font-medium">
              Leider wurden keine Mitglieder gefunden für: "{{ searchString }}"
            </span>
          </div>
        </div>
      </div>

      <div v-if="mitgliederLaden" class="grid place-items-center h-full mt-20">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="
              margin: auto;
              background: none;
              display: block;
              shape-rendering: auto;
            "
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <path
              fill="none"
              stroke="#57a25e"
              stroke-width="8"
              stroke-dasharray="42.76482137044271 42.76482137044271"
              d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
              stroke-linecap="round"
              style="transform: scale(0.8); transform-origin: 50px 50px"
            >
              <animate
                attributeName="stroke-dashoffset"
                repeatCount="indefinite"
                dur="1.5384615384615383s"
                keyTimes="0;1"
                values="0;256.58892822265625"
              ></animate>
            </path>
          </svg>
        </div>
        <div class="content-center">
          <span class="text-2xl font-medium"> Laden der Mitglieder ... </span>
        </div>
      </div>
      <div
        v-if="mitgliederNichtGeladen"
        class="grid place-items-center h-full mt-20"
      >
        <div>
          <svg
            width="160px"
            height="160px"
            viewBox="0 0 24 24"
            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlns:cc="http://creativecommons.org/ns#"
            xmlns:dc="http://purl.org/dc/elements/1.1/"
          >
            <g transform="translate(0 -1028.4)">
              <path
                d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                transform="translate(0 1029.4)"
                fill="#c0392b"
              />
              <path
                d="m22 12c0 5.523-4.477 10-10 10-5.5228 0-10-4.477-10-10 0-5.5228 4.4772-10 10-10 5.523 0 10 4.4772 10 10z"
                transform="translate(0 1028.4)"
                fill="#e74c3c"
              />
              <path
                d="m7.0503 1037.8 3.5357 3.6-3.5357 3.5 1.4142 1.4 3.5355-3.5 3.536 3.5 1.414-1.4-3.536-3.5 3.536-3.6-1.414-1.4-3.536 3.5-3.5355-3.5-1.4142 1.4z"
                fill="#c0392b"
              />
              <path
                d="m7.0503 1036.8 3.5357 3.6-3.5357 3.5 1.4142 1.4 3.5355-3.5 3.536 3.5 1.414-1.4-3.536-3.5 3.536-3.6-1.414-1.4-3.536 3.5-3.5355-3.5-1.4142 1.4z"
                fill="#ecf0f1"
              />
            </g>
          </svg>
        </div>
        <div class="content-center">
          <span class="text-2xl font-medium">
            Es wurden keine Mitglieder in der Datenbank gefunden! Sie müssen ein
            Backup durchführen!
          </span>
        </div>
        <div class="content-center mt-6">
          <button
            @click="loadBackup(), changeBoolean()"
            class="
              bg-green-500
              hover:bg-green-600
              text-white
              font-bold
              py-4
              px-8
              rounded
              inline-flex
              items-center
            "
          >
            <span class="md:text-xl">Backup durchführen</span>
          </button>
        </div>
      </div>
    </div>
    <!--PopUp Backup -->
    <div
      v-if="showBackupPopup"
      class="
        bg-black
        fixed
        inset-0
        z-30
        flex
        items-center
        justify-center
        bg-opacity-70
        text-black
      "
    >
      <div class="bg-white p-12 rounded-md relative">
        <div v-if="showbackupNormaleAnsicht">
          <div v-if="oberesDivInBackupPopUP">
            <div class="absolute top-0 right-0">
              <button
                @click="triggerPopup()"
                class="
                  inline-flex
                  items-center
                  justify-center
                  w-10
                  h-10
                  mr-3
                  mt-3
                  text-white
                  transition-colors
                  duration-150
                  bg-red-400
                  rounded-lg
                  focus:shadow-outline
                  hover:bg-red-300
                "
              >
                X
              </button>
            </div>
            <p class="text-xl mb-5">Backup erstellen</p>
            <button
              @click="BackupDownload()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                mb-5
                rounded
                inline-flex
                items-center
              "
            >
              <span class="text-xs md:text-base">Backup herunterladen</span>
            </button>
            <hr class="bg-gray-400 border-2 border-gray-400" />
          </div>
          <p class="text-xl mt-5">Daten aus Backup wiederherstellen</p>
          <p class="mt-3 mb-1">Bitte wählen Sie Ihre Backup Datei:</p>
          <form>
            <input
              id="backupFile"
              type="file"
              accept=".json"
              class="
                border-0
                px-3
                py-2.5
                text-blueGray-600
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
                ease-linear
                transition-all
                duration-150
              "
            />
          </form>
          <div class="mt-2 text-red-600" v-if="textKeineDateiAusgewaehlt">
            Keine Datei ausgewählt!
          </div>
          <div>
            <p v-if="oberesDivInBackupPopUP" class="mt-5 text-red-600">
              ACHTUNG: Mit dem Laden der Backup Datei,<br />
              löschen/überschreiben Sie alle vorhandenen Daten!
            </p>
            <button
              @click="BackupImportieren()"
              class="
                bg-green-500
                hover:bg-green-600
                text-white
                font-bold
                py-2
                px-4
                rounded
                inline-flex
                items-center
                mt-3
              "
            >
              <span class="text-xs md:text-base">Backup importieren</span>
            </button>
          </div>
        </div>
        <div v-if="showBackupLoadingIcon">
          {{ this.textBackupPopUp }}
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-10 w-10 animate-spin mt-5 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </div>
        </div>
        <div class="form-group mt-3">
          <div v-if="projektServerResponse" role="alert">
            <div
              class="
                bg-red-500
                text-white
                font-bold
                rounded-t
                px-4
                py-2
                text-center
              "
            >
              Leider ist ein Fehler aufgetreten.
            </div>
            <div
              class="
                border border-t-0 border-red-400
                rounded-b
                bg-red-100
                px-4
                py-3
                text-red-700
              "
            >
              <p class="text-center">Folgender Fehler ist aufgetreten:</p>
              <p class="text-center font-bold">{{ projektServerResponse }}</p>
              <p>
                Versuchen Sie es später erneut, oder melden Sie den Fehler dem
                Administrator.
              </p>
              <p>
                Bitte warten Sie ca. 30 Sekunden, bis Sie die Seite neu Laden
                und zu der Mitgliederübersicht zurückkehren
              </p>
            </div>
            <button
              @click="reloadPage"
              class="
                bg-green-600
                hover:bg-green-500
                mr-20
                mb-5
                text-white
                font-bold
                uppercase
                w-full
                mt-5
                text-xs
                px-6
                py-3
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                mr-1
                ease-linear
                transition-all
                duration-150
              "
            >
              Zurück zur Mitgliederübersicht
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--PopUp Löschen -->
    <div
      v-if="showPopupLoeschen"
      class="
        popup
        bg-black
        fixed
        inset-0
        flex
        items-center
        justify-center
        z-30
        bg-opacity-70
        text-black
      "
    >
      <div class="bg-white px-24 py-12 rounded-md relative">
        Möchtest du das Mitglied <br /><strong
          v-for="person in this.aktuellesMitglied.Personen"
          :key="person.id"
        >
          {{ person.Name }} {{ person.Vorname }}
        </strong>
        <br />
        für immer löschen?

        <div v-if="showConfirmMessage" class="mt-4">
          <div class="absolute bottom-0 left-0 mt-10">
            <button
              id="buttonVerwerfen"
              @click="triggerPopupLoeschen()"
              class="
                mb-5
                ml-12
                bg-red-700
                hover:bg-red-600
                text-white
                font-bold
                uppercase
                text-xs
                px-4
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Abbrechen
            </button>
          </div>

          <div class="absolute bottom-0 right-0">
            <button
              id="buttonLoeschen"
              @click="deleteMitglied()"
              class="
                mb-5
                mr-12
                mt-5
                bg-green-700
                hover:bg-green-600
                text-white
                font-bold
                uppercase
                text-xs
                px-12
                py-2
                rounded
                shadow
                hover:shadow-md
                outline-none
                focus:outline-none
                ease-linear
                transition-all
                duration-150
              "
              type="button"
            >
              Ja
            </button>
          </div>
        </div>

        <div v-if="!projektServerResponse && !showConfirmMessage">
          Das Mitglied wird gelöscht ...

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-10 w-10 ml-28 animate-spin mt-5 text-green-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </div>
        <div v-if="projektServerResponse" role="alert">
          <div
            class="
              bg-red-500
              text-white
              font-bold
              rounded-t
              px-4
              py-2
              text-center
            "
          >
            Leider ist ein Fehler aufgetreten.
          </div>
          <div
            class="
              border border-t-0 border-red-400
              rounded-b
              bg-red-100
              px-4
              py-3
              text-red-700
            "
          >
            <p class="text-center">Folgender Fehler ist aufgetreten:</p>
            <p class="text-center font-bold">{{ projektServerResponse }}</p>
            <p>
              Versuchen Sie es später erneut, oder melden Sie den Fehler dem
              Administrator.
            </p>
            <p>
              Bitte warten Sie ca. 30 Sekunden, bis Sie die Seite neu Laden und
              zum Projekt zurückkehren
            </p>
          </div>
          <button
            @click="reloadPage"
            class="
              bg-green-600
              hover:bg-green-500
              mr-20
              mb-5
              text-white
              font-bold
              uppercase
              w-full
              mt-5
              text-xs
              px-6
              py-3
              rounded
              shadow
              hover:shadow-md
              outline-none
              focus:outline-none
              mr-1
              ease-linear
              transition-all
              duration-150
            "
          >
            Zurück zur Projektübersicht
          </button>
        </div>
      </div>
    </div>
  </div>
</template>





<script>
import dataService from "../services/dataService";
import _ from "lodash";

export default {
  name: "MitgliederTable",

  async mounted() {
    document
      .getElementById("hilfsprojekteSidebar")
      .classList.remove("bg-green-700");
    document.getElementById("mitgliederSidebar").classList.add("bg-green-700");
    this.mitgliederLaden = true;
    this.showButtons = false;

    this.getMitglieder();
    this.debouncedOrderedAndFilteredMitglieder = _.debounce(
      this.orderedAndFilteredMitglieder,
      400
    );
  },
  watch: {
    searchString: function () {
      this.debouncedOrderedAndFilteredMitglieder();
    },
    mitglieder: function () {
      this.debouncedOrderedAndFilteredMitglieder();
    },
  },
  data() {
    return {
      //Backup related
      //show Popup für Backup File
      showBackupPopup: false,
      //LadeIcon für Backup
      showBackupLoadingIcon: false,
      showbackupNormaleAnsicht: true,
      oberesDivInBackupPopUP: true,
      textKeineDateiAusgewaehlt: false,
      showButtons: true,
      mitgliederLaden: true,
      mitgliederNichtGeladen: false,
      sucheFehlgeschlagen: false,

      mitglieder: [],
      personen: [],
      searchString: "",
      organisation: [],
      aktuellesMitglied: null,
      showPopupLoeschen: false,
      showConfirmMessage: true,
      projektServerResponse: "",

      //gefilterte Mitglieder => für suche
      filteredMitglieder: [],
    };
  },

  methods: {
    //Backup Methoden
    loadBackup: function () {
      this.showBackupPopup = true;
    },

    async BackupImportieren() {
      //Datei holen
      var backupFile = document.getElementById("backupFile").files[0];

      //Falls Kein BackupFile Ausgewählt wurde Erinnern dass zuerst ein Backup file importiert werden muss!
      if (backupFile == null) {
        this.textKeineDateiAusgewaehlt = true;
      } else {
        this.textBackupPopUp =
          "Backup wird in die Datenbank geladen. Dies kann einen Moment dauern.";
        this.showbackupNormaleAnsicht = false;
        //LadeIcon anzeigen
        this.showBackupLoadingIcon = true;

        var jsonBackup = await this.getTextFromJSONFile(backupFile);
        jsonBackup = JSON.parse(jsonBackup);

        //Daten dem DataService übergeben
        try {
          await dataService.restoreMitgliederBackup(jsonBackup);

          //LadeIcon ausblenden
          this.showBackupLoadingIcon = false;
          //Popup schliessen
          this.showBackupPopup = false;
          //Seite neu laden
          window.location.reload();
        } catch (error) {
          this.projektServerResponse = error.message;
        }
      }
    },

    triggerPopup() {
      this.showBackupPopup = !this.showBackupPopup;
    },

    changeBoolean() {
      this.oberesDivInBackupPopUP = false;
    },

    getTextFromJSONFile: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      }),

    async BackupDownload() {
      this.textBackupPopUp =
        "Backup wird heruntergeladen. Dies kann einen Moment dauern.";
      this.showbackupNormaleAnsicht = false;
      this.showBackupLoadingIcon = true;

      var backupdata = await dataService.getAlleMitglieder();
      backupdata = JSON.stringify(backupdata.data);

      //Timestamp für Filename
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
      var dateTime = date + "-" + time;

      const file = new Blob([backupdata], { type: "text/json;charset=utf-8" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(file);
      a.download = "Backup_Mitglieder_Verwaltung-" + dateTime + ".json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      //LadeIcon ausblenden
      this.showBackupLoadingIcon = false;
      //Popup schliessen
      this.showBackupPopup = false;
      //Seite neu laden
      window.location.reload();
    },

    onDetails: function (id) {
      this.$router.push({ name: "mitgliederDetail", params: { id: id } });
    },
    reloadPage() {
      window.location.reload();
    },
    onCreateMitglied() {
      this.$router.push({ name: "createMitglied" });
    },
    async getMitglieder() {
      const response = await dataService.getAlleMitglieder();
      if (response.data.length == 0) {
        this.sucheFehlgeschlagen = false;
        this.mitgliederLaden = false;
        this.mitgliederNichtGeladen = true;
        this.showButtons = false;
      }
      if (response.data.length > 0) {
        this.mitgliederLaden = false;
        this.showButtons = true;
      }

      //Mitglieder in Array Laden
      this.mitglieder = response.data;
    },
    async deleteMitglied() {
      this.showConfirmMessage = false;
      try {
        await dataService.deleteMitglied(this.aktuellesMitglied);
        window.location.reload();
      } catch (error) {
        this.projektServerResponse = error.message;
      }
    },
    //Zu löschendes Mitglied definieren
    sendMitglied(zuloeschendesMitglied) {
      this.aktuellesMitglied = zuloeschendesMitglied;
    },
    triggerPopupLoeschen() {
      //Buttons unsichtbar machen
      this.showPopupLoeschen = !this.showPopupLoeschen;
    },
    generateCsvFromFilteredData: function () {
      var gefilterteMitglieder = this.filteredMitglieder;
      var neuesCsv = "data:text/csv;charset=utf-8,";
      var maxPersonen = _.maxBy(gefilterteMitglieder, function (o) {
        return o.Personen.length;
      });
      maxPersonen = maxPersonen.Personen.length;

      //HeaderZeile einfügen in CSV
      var mitgliederHeaderRow =
        "Id;Alte-Id;Organisation;Organisation Ort;Anrede;Briefanrede;Adresse;Adresszusatz;PLZ;Ort;Aktivmitglied;Passivmitglied;Präsident;Kassier;Vorstand";
      var personenHeaderRow = "";
      for (let index = 1; index <= maxPersonen; index++) {
        personenHeaderRow +=
          ";Person " +
          index +
          " Nachname;" +
          "Person " +
          index +
          " Vorname;" +
          "Person " +
          index +
          " Geschlecht";
      }
      neuesCsv += mitgliederHeaderRow + personenHeaderRow + "\r\n";

      gefilterteMitglieder.forEach(function (mitglied) {
        let mitgliederDaten =
          mitglied.id +
          ";" +
          mitglied.alteId +
          ";" +
          (mitglied.Organisation == null
            ? ""
            : mitglied.Organisation.Name != null
            ? mitglied.Organisation.Name
            : "") +
          ";" +
          (mitglied.Organisation == null
            ? ""
            : mitglied.Organisation.Adresse != null
            ? mitglied.Organisation.Adresse
            : "") +
          ";" +
          (mitglied.Anrede == null ? "" : mitglied.Anrede) +
          ";" +
          (mitglied.Briefanrede == null ? "" : mitglied.Briefanrede) +
          ";" +
          mitglied.Adresse +
          ";" +
          mitglied.Adresszusatz +
          ";" +
          mitglied.PLZ +
          ";" +
          mitglied.Ort +
          ";" +
          (mitglied.IsAktivmitglied ? "Ja" : "Nein") +
          ";" +
          (mitglied.IsPassivmitglied ? "Ja" : "Nein") +
          ";" +
          (mitglied.IsPraesident ? "Ja" : "Nein") +
          ";" +
          (mitglied.IsKassier ? "Ja" : "Nein") +
          ";" +
          (mitglied.IsVorstand ? "Ja" : "Nein");
        let personen = "";
        mitglied.Personen.forEach((person) => {
          //zahlung.Bemerkung = zahlung.Bemerkung.replace(/;/g, ":")
          let einePerson =
            ";" + person.Name + ";" + person.Vorname + ";" + person.Geschlecht;
          personen += einePerson;
        });

        let row = mitgliederDaten + personen;
        //let row = rowArray.join(",");

        neuesCsv += row + "\r\n";
      });
      this.csvContent = neuesCsv;
      const a = document.createElement("a");
      a.href = this.csvContent;
      a.download = "Export_Mitglieder_Verwaltung";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    //Mitglider sortieren und filtern via search
    orderedAndFilteredMitglieder: function () {
      var mitgliederSorted = _.orderBy(this.mitglieder, "id");

      //Organisationen und Personen füllen, falls null => nur zum filtern
      for (let index = 0; index < mitgliederSorted.length; index++) {
        const element = mitgliederSorted[index];
        if (element.Organisation == null) {
          element.Organisation = {
            Name: "",
          };
        }
      }
      if (this.searchString != "") {
        mitgliederSorted = mitgliederSorted.filter(
          (mitglied) =>
            (mitglied.Organisation != null
              ? mitglied.Organisation.Name.toLowerCase().includes(
                  this.searchString.toLowerCase()
                )
              : false) ||
            (mitglied.Personen.length > 0
              ? mitglied.Personen.some((person) =>
                  person.Name.toLowerCase().includes(
                    this.searchString.toLowerCase()
                  )
                )
              : false) ||
            mitglied.Ort.toLowerCase().includes(this.searchString.toLowerCase())
        );
      }
      this.filteredMitglieder = mitgliederSorted;
      if (this.filteredMitglieder.length == 0) {
        this.sucheFehlgeschlagen = true;
      }
    },
  },
};
</script>



<style scoped>
</style>
<template>
  <div
    class="
      bg-pilatus bg-cover bg-center
      min-h-screen
      text-gray-800
      px-4
      py-6
      flex flex-col
      justify-center
      sm:py-12
    "
  >
    <div class="relative py-3 sm:max-w-xl mx-auto text-center">
      <span class="text-2xl font-light text-white"
        >Anmeldung für die Hilfsprojekte-Verwaltung</span
      >
      <div class="relative mt-4 bg-white shadow-md sm:rounded-lg text-left">
        <div class="h-2 bg-green-600 rounded-t-md"></div>
        <div class="py-6 px-8">
          <Form @submit="handleLogin" :validation-schema="schema">
            <div class="form-group">
              <label for="passwort" class="block mt-3 font-semibold"
                >Passwort</label
              >
              <Field
                name="passwort"
                type="password"
                class="
                  border
                  w-full
                  h-5
                  px-3
                  py-5
                  mt-2
                  hover:outline-none
                  focus:outline-none focus:ring-1 focus:ring-indigo-600
                  rounded-md
                "
              />
              <ErrorMessage name="passwort" class="error-feedback" />
            </div>
            <div class="flex justify-between items-baseline">
              <div class="form-group">
                <button
                  class="mt-4 bg-green-800 text-white py-2 px-6 rounded-lg"
                >
                  <span> Login </span>
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div class="form-group mt-3">
        <div v-if="message" role="alert">
          <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
            Login Ungültig
          </div>
          <div
            class="
              border border-t-0 border-red-400
              rounded-b
              bg-red-100
              px-4
              py-3
              text-red-700
            "
          >
            <p>{{ message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "LoginForm",
  components: {
    Form,
    Field,
    ErrorMessage,
  },

  data() {
    const schema = yup.object().shape({
      passwort: yup.string().required("Passwort darf nicht leer sein!"),
    });
    return {
      loading: false,
      message: "",
      schema,
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  created() {
    if (this.loggedIn) {
      this.$router.push("/hilfsprojekte");
    }
  },

  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/hilfsprojekte");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>


<script>
</script>


<style scoped>
</style>